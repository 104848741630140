.dropzoneWrapper{
    flex: 1;
    display: flex;
    height: 289px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    border-width: 3px;
    border-radius: 4px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafac2;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
.dropzone{
    position: relative;
    background-color: #fafafa;
    border-radius: 4px;
    overflow: hidden;
}
.thumb {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    padding: 0;
    box-sizing: border-box;
    min-width: 227px;
    max-width: 227px;
    overflow: hidden;
    height: 222px;/* 269px; */
}
.innerThumb {
    width: 100%;
    height: 160px;
    background-color: #e1dede;
}
.thumbsWrapper {
    display: flex;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;
}
.img {
    display: block;
    max-width: 100%;
    width: auto;
    height: 100%;
    margin: auto;
}
.readyForDrop{
    z-index: 2;
    position: relative;
}