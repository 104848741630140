* {
  margin: 0;
  padding: 0;
}

html,
body, #root {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

body {
  background-color: #021027;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
          mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
}

.circleContainer {
  position: absolute;
  transform: translateY(-10vh);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.circleContainer .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#99ffff, #99ffff 10%, rgba(153, 255, 255, 0) 56%);
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
          animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circleContainer:nth-child(1) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-1;
          animation-name: move-frames-1;
  -webkit-animation-duration: 36659ms;
          animation-duration: 36659ms;
  -webkit-animation-delay: 20794ms;
          animation-delay: 20794ms;
}
@-webkit-keyframes move-frames-1 {
  from {
    transform: translate3d(65vw, 106vh, 0);
  }
  to {
    transform: translate3d(92vw, -119vh, 0);
  }
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(65vw, 106vh, 0);
  }
  to {
    transform: translate3d(92vw, -119vh, 0);
  }
}
.circleContainer:nth-child(1) .circle {
  -webkit-animation-delay: 2653ms;
          animation-delay: 2653ms;
}
.circleContainer:nth-child(2) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-2;
          animation-name: move-frames-2;
  -webkit-animation-duration: 30477ms;
          animation-duration: 30477ms;
  -webkit-animation-delay: 23677ms;
          animation-delay: 23677ms;
}
@-webkit-keyframes move-frames-2 {
  from {
    transform: translate3d(83vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -108vh, 0);
  }
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(83vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -108vh, 0);
  }
}
.circleContainer:nth-child(2) .circle {
  -webkit-animation-delay: 3216ms;
          animation-delay: 3216ms;
}
.circleContainer:nth-child(3) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-3;
          animation-name: move-frames-3;
  -webkit-animation-duration: 31869ms;
          animation-duration: 31869ms;
  -webkit-animation-delay: 35686ms;
          animation-delay: 35686ms;
}
@-webkit-keyframes move-frames-3 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(67vw, -120vh, 0);
  }
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(67vw, -120vh, 0);
  }
}
.circleContainer:nth-child(3) .circle {
  -webkit-animation-delay: 3324ms;
          animation-delay: 3324ms;
}
.circleContainer:nth-child(4) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-4;
          animation-name: move-frames-4;
  -webkit-animation-duration: 30661ms;
          animation-duration: 30661ms;
  -webkit-animation-delay: 19043ms;
          animation-delay: 19043ms;
}
@-webkit-keyframes move-frames-4 {
  from {
    transform: translate3d(30vw, 110vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(30vw, 110vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
.circleContainer:nth-child(4) .circle {
  -webkit-animation-delay: 1016ms;
          animation-delay: 1016ms;
}
.circleContainer:nth-child(5) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-5;
          animation-name: move-frames-5;
  -webkit-animation-duration: 28325ms;
          animation-duration: 28325ms;
  -webkit-animation-delay: 35157ms;
          animation-delay: 35157ms;
}
@-webkit-keyframes move-frames-5 {
  from {
    transform: translate3d(83vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -112vh, 0);
  }
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(83vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -112vh, 0);
  }
}
.circleContainer:nth-child(5) .circle {
  -webkit-animation-delay: 3227ms;
          animation-delay: 3227ms;
}
.circleContainer:nth-child(6) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-6;
          animation-name: move-frames-6;
  -webkit-animation-duration: 36207ms;
          animation-duration: 36207ms;
  -webkit-animation-delay: 24150ms;
          animation-delay: 24150ms;
}
@-webkit-keyframes move-frames-6 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(83vw, -128vh, 0);
  }
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(83vw, -128vh, 0);
  }
}
.circleContainer:nth-child(6) .circle {
  -webkit-animation-delay: 288ms;
          animation-delay: 288ms;
}
.circleContainer:nth-child(7) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-7;
          animation-name: move-frames-7;
  -webkit-animation-duration: 34894ms;
          animation-duration: 34894ms;
  -webkit-animation-delay: 24422ms;
          animation-delay: 24422ms;
}
@-webkit-keyframes move-frames-7 {
  from {
    transform: translate3d(89vw, 102vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(89vw, 102vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
.circleContainer:nth-child(7) .circle {
  -webkit-animation-delay: 1312ms;
          animation-delay: 1312ms;
}
.circleContainer:nth-child(8) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-8;
          animation-name: move-frames-8;
  -webkit-animation-duration: 36090ms;
          animation-duration: 36090ms;
  -webkit-animation-delay: 28135ms;
          animation-delay: 28135ms;
}
@-webkit-keyframes move-frames-8 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -124vh, 0);
  }
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -124vh, 0);
  }
}
.circleContainer:nth-child(8) .circle {
  -webkit-animation-delay: 778ms;
          animation-delay: 778ms;
}
.circleContainer:nth-child(9) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-9;
          animation-name: move-frames-9;
  -webkit-animation-duration: 35517ms;
          animation-duration: 35517ms;
  -webkit-animation-delay: 35814ms;
          animation-delay: 35814ms;
}
@-webkit-keyframes move-frames-9 {
  from {
    transform: translate3d(74vw, 104vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(74vw, 104vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
.circleContainer:nth-child(9) .circle {
  -webkit-animation-delay: 348ms;
          animation-delay: 348ms;
}
.circleContainer:nth-child(10) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-10;
          animation-name: move-frames-10;
  -webkit-animation-duration: 28244ms;
          animation-duration: 28244ms;
  -webkit-animation-delay: 322ms;
          animation-delay: 322ms;
}
@-webkit-keyframes move-frames-10 {
  from {
    transform: translate3d(71vw, 109vh, 0);
  }
  to {
    transform: translate3d(68vw, -111vh, 0);
  }
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(71vw, 109vh, 0);
  }
  to {
    transform: translate3d(68vw, -111vh, 0);
  }
}
.circleContainer:nth-child(10) .circle {
  -webkit-animation-delay: 1600ms;
          animation-delay: 1600ms;
}
.circleContainer:nth-child(11) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-11;
          animation-name: move-frames-11;
  -webkit-animation-duration: 32238ms;
          animation-duration: 32238ms;
  -webkit-animation-delay: 23127ms;
          animation-delay: 23127ms;
}
@-webkit-keyframes move-frames-11 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -104vh, 0);
  }
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -104vh, 0);
  }
}
.circleContainer:nth-child(11) .circle {
  -webkit-animation-delay: 3796ms;
          animation-delay: 3796ms;
}
.circleContainer:nth-child(12) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-12;
          animation-name: move-frames-12;
  -webkit-animation-duration: 33467ms;
          animation-duration: 33467ms;
  -webkit-animation-delay: 23730ms;
          animation-delay: 23730ms;
}
@-webkit-keyframes move-frames-12 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(39vw, -122vh, 0);
  }
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(39vw, -122vh, 0);
  }
}
.circleContainer:nth-child(12) .circle {
  -webkit-animation-delay: 534ms;
          animation-delay: 534ms;
}
.circleContainer:nth-child(13) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-13;
          animation-name: move-frames-13;
  -webkit-animation-duration: 34895ms;
          animation-duration: 34895ms;
  -webkit-animation-delay: 24999ms;
          animation-delay: 24999ms;
}
@-webkit-keyframes move-frames-13 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -121vh, 0);
  }
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -121vh, 0);
  }
}
.circleContainer:nth-child(13) .circle {
  -webkit-animation-delay: 3939ms;
          animation-delay: 3939ms;
}
.circleContainer:nth-child(14) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-14;
          animation-name: move-frames-14;
  -webkit-animation-duration: 35125ms;
          animation-duration: 35125ms;
  -webkit-animation-delay: 14740ms;
          animation-delay: 14740ms;
}
@-webkit-keyframes move-frames-14 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -137vh, 0);
  }
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -137vh, 0);
  }
}
.circleContainer:nth-child(14) .circle {
  -webkit-animation-delay: 67ms;
          animation-delay: 67ms;
}
.circleContainer:nth-child(15) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-15;
          animation-name: move-frames-15;
  -webkit-animation-duration: 36099ms;
          animation-duration: 36099ms;
  -webkit-animation-delay: 3026ms;
          animation-delay: 3026ms;
}
@-webkit-keyframes move-frames-15 {
  from {
    transform: translate3d(90vw, 110vh, 0);
  }
  to {
    transform: translate3d(41vw, -125vh, 0);
  }
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(90vw, 110vh, 0);
  }
  to {
    transform: translate3d(41vw, -125vh, 0);
  }
}
.circleContainer:nth-child(15) .circle {
  -webkit-animation-delay: 1388ms;
          animation-delay: 1388ms;
}
.circleContainer:nth-child(16) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-16;
          animation-name: move-frames-16;
  -webkit-animation-duration: 28115ms;
          animation-duration: 28115ms;
  -webkit-animation-delay: 1552ms;
          animation-delay: 1552ms;
}
@-webkit-keyframes move-frames-16 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(50vw, -130vh, 0);
  }
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(50vw, -130vh, 0);
  }
}
.circleContainer:nth-child(16) .circle {
  -webkit-animation-delay: 3241ms;
          animation-delay: 3241ms;
}
.circleContainer:nth-child(17) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-17;
          animation-name: move-frames-17;
  -webkit-animation-duration: 28824ms;
          animation-duration: 28824ms;
  -webkit-animation-delay: 16806ms;
          animation-delay: 16806ms;
}
@-webkit-keyframes move-frames-17 {
  from {
    transform: translate3d(88vw, 109vh, 0);
  }
  to {
    transform: translate3d(62vw, -120vh, 0);
  }
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(88vw, 109vh, 0);
  }
  to {
    transform: translate3d(62vw, -120vh, 0);
  }
}
.circleContainer:nth-child(17) .circle {
  -webkit-animation-delay: 2802ms;
          animation-delay: 2802ms;
}
.circleContainer:nth-child(18) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-18;
          animation-name: move-frames-18;
  -webkit-animation-duration: 34929ms;
          animation-duration: 34929ms;
  -webkit-animation-delay: 29066ms;
          animation-delay: 29066ms;
}
@-webkit-keyframes move-frames-18 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -127vh, 0);
  }
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -127vh, 0);
  }
}
.circleContainer:nth-child(18) .circle {
  -webkit-animation-delay: 1707ms;
          animation-delay: 1707ms;
}
.circleContainer:nth-child(19) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-19;
          animation-name: move-frames-19;
  -webkit-animation-duration: 36768ms;
          animation-duration: 36768ms;
  -webkit-animation-delay: 31659ms;
          animation-delay: 31659ms;
}
@-webkit-keyframes move-frames-19 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(21vw, -108vh, 0);
  }
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(21vw, -108vh, 0);
  }
}
.circleContainer:nth-child(19) .circle {
  -webkit-animation-delay: 605ms;
          animation-delay: 605ms;
}
.circleContainer:nth-child(20) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-20;
          animation-name: move-frames-20;
  -webkit-animation-duration: 30084ms;
          animation-duration: 30084ms;
  -webkit-animation-delay: 27478ms;
          animation-delay: 27478ms;
}
@-webkit-keyframes move-frames-20 {
  from {
    transform: translate3d(53vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -115vh, 0);
  }
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(53vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -115vh, 0);
  }
}
.circleContainer:nth-child(20) .circle {
  -webkit-animation-delay: 3106ms;
          animation-delay: 3106ms;
}
.circleContainer:nth-child(21) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-21;
          animation-name: move-frames-21;
  -webkit-animation-duration: 36657ms;
          animation-duration: 36657ms;
  -webkit-animation-delay: 401ms;
          animation-delay: 401ms;
}
@-webkit-keyframes move-frames-21 {
  from {
    transform: translate3d(22vw, 105vh, 0);
  }
  to {
    transform: translate3d(36vw, -129vh, 0);
  }
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(22vw, 105vh, 0);
  }
  to {
    transform: translate3d(36vw, -129vh, 0);
  }
}
.circleContainer:nth-child(21) .circle {
  -webkit-animation-delay: 417ms;
          animation-delay: 417ms;
}
.circleContainer:nth-child(22) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-22;
          animation-name: move-frames-22;
  -webkit-animation-duration: 32967ms;
          animation-duration: 32967ms;
  -webkit-animation-delay: 32781ms;
          animation-delay: 32781ms;
}
@-webkit-keyframes move-frames-22 {
  from {
    transform: translate3d(88vw, 104vh, 0);
  }
  to {
    transform: translate3d(17vw, -111vh, 0);
  }
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(88vw, 104vh, 0);
  }
  to {
    transform: translate3d(17vw, -111vh, 0);
  }
}
.circleContainer:nth-child(22) .circle {
  -webkit-animation-delay: 2157ms;
          animation-delay: 2157ms;
}
.circleContainer:nth-child(23) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-23;
          animation-name: move-frames-23;
  -webkit-animation-duration: 29478ms;
          animation-duration: 29478ms;
  -webkit-animation-delay: 14594ms;
          animation-delay: 14594ms;
}
@-webkit-keyframes move-frames-23 {
  from {
    transform: translate3d(84vw, 110vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(84vw, 110vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
.circleContainer:nth-child(23) .circle {
  -webkit-animation-delay: 2677ms;
          animation-delay: 2677ms;
}
.circleContainer:nth-child(24) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-24;
          animation-name: move-frames-24;
  -webkit-animation-duration: 36641ms;
          animation-duration: 36641ms;
  -webkit-animation-delay: 36934ms;
          animation-delay: 36934ms;
}
@-webkit-keyframes move-frames-24 {
  from {
    transform: translate3d(79vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(79vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}
.circleContainer:nth-child(24) .circle {
  -webkit-animation-delay: 3478ms;
          animation-delay: 3478ms;
}
.circleContainer:nth-child(25) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-25;
          animation-name: move-frames-25;
  -webkit-animation-duration: 28277ms;
          animation-duration: 28277ms;
  -webkit-animation-delay: 25721ms;
          animation-delay: 25721ms;
}
@-webkit-keyframes move-frames-25 {
  from {
    transform: translate3d(20vw, 105vh, 0);
  }
  to {
    transform: translate3d(30vw, -126vh, 0);
  }
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(20vw, 105vh, 0);
  }
  to {
    transform: translate3d(30vw, -126vh, 0);
  }
}
.circleContainer:nth-child(25) .circle {
  -webkit-animation-delay: 425ms;
          animation-delay: 425ms;
}
.circleContainer:nth-child(26) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-26;
          animation-name: move-frames-26;
  -webkit-animation-duration: 36608ms;
          animation-duration: 36608ms;
  -webkit-animation-delay: 33298ms;
          animation-delay: 33298ms;
}
@-webkit-keyframes move-frames-26 {
  from {
    transform: translate3d(40vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -133vh, 0);
  }
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(40vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -133vh, 0);
  }
}
.circleContainer:nth-child(26) .circle {
  -webkit-animation-delay: 2878ms;
          animation-delay: 2878ms;
}
.circleContainer:nth-child(27) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-27;
          animation-name: move-frames-27;
  -webkit-animation-duration: 33247ms;
          animation-duration: 33247ms;
  -webkit-animation-delay: 25399ms;
          animation-delay: 25399ms;
}
@-webkit-keyframes move-frames-27 {
  from {
    transform: translate3d(78vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -124vh, 0);
  }
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(78vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -124vh, 0);
  }
}
.circleContainer:nth-child(27) .circle {
  -webkit-animation-delay: 1573ms;
          animation-delay: 1573ms;
}
.circleContainer:nth-child(28) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-28;
          animation-name: move-frames-28;
  -webkit-animation-duration: 33232ms;
          animation-duration: 33232ms;
  -webkit-animation-delay: 2210ms;
          animation-delay: 2210ms;
}
@-webkit-keyframes move-frames-28 {
  from {
    transform: translate3d(79vw, 109vh, 0);
  }
  to {
    transform: translate3d(85vw, -135vh, 0);
  }
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(79vw, 109vh, 0);
  }
  to {
    transform: translate3d(85vw, -135vh, 0);
  }
}
.circleContainer:nth-child(28) .circle {
  -webkit-animation-delay: 3768ms;
          animation-delay: 3768ms;
}
.circleContainer:nth-child(29) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-29;
          animation-name: move-frames-29;
  -webkit-animation-duration: 36717ms;
          animation-duration: 36717ms;
  -webkit-animation-delay: 5302ms;
          animation-delay: 5302ms;
}
@-webkit-keyframes move-frames-29 {
  from {
    transform: translate3d(28vw, 107vh, 0);
  }
  to {
    transform: translate3d(31vw, -132vh, 0);
  }
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(28vw, 107vh, 0);
  }
  to {
    transform: translate3d(31vw, -132vh, 0);
  }
}
.circleContainer:nth-child(29) .circle {
  -webkit-animation-delay: 3178ms;
          animation-delay: 3178ms;
}
.circleContainer:nth-child(30) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-30;
          animation-name: move-frames-30;
  -webkit-animation-duration: 29307ms;
          animation-duration: 29307ms;
  -webkit-animation-delay: 20445ms;
          animation-delay: 20445ms;
}
@-webkit-keyframes move-frames-30 {
  from {
    transform: translate3d(71vw, 109vh, 0);
  }
  to {
    transform: translate3d(3vw, -131vh, 0);
  }
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(71vw, 109vh, 0);
  }
  to {
    transform: translate3d(3vw, -131vh, 0);
  }
}
.circleContainer:nth-child(30) .circle {
  -webkit-animation-delay: 1478ms;
          animation-delay: 1478ms;
}
.circleContainer:nth-child(31) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-31;
          animation-name: move-frames-31;
  -webkit-animation-duration: 30980ms;
          animation-duration: 30980ms;
  -webkit-animation-delay: 1917ms;
          animation-delay: 1917ms;
}
@-webkit-keyframes move-frames-31 {
  from {
    transform: translate3d(59vw, 106vh, 0);
  }
  to {
    transform: translate3d(19vw, -119vh, 0);
  }
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(59vw, 106vh, 0);
  }
  to {
    transform: translate3d(19vw, -119vh, 0);
  }
}
.circleContainer:nth-child(31) .circle {
  -webkit-animation-delay: 945ms;
          animation-delay: 945ms;
}
.circleContainer:nth-child(32) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-32;
          animation-name: move-frames-32;
  -webkit-animation-duration: 36718ms;
          animation-duration: 36718ms;
  -webkit-animation-delay: 35186ms;
          animation-delay: 35186ms;
}
@-webkit-keyframes move-frames-32 {
  from {
    transform: translate3d(72vw, 110vh, 0);
  }
  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(72vw, 110vh, 0);
  }
  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}
.circleContainer:nth-child(32) .circle {
  -webkit-animation-delay: 1483ms;
          animation-delay: 1483ms;
}
.circleContainer:nth-child(33) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-33;
          animation-name: move-frames-33;
  -webkit-animation-duration: 33712ms;
          animation-duration: 33712ms;
  -webkit-animation-delay: 16951ms;
          animation-delay: 16951ms;
}
@-webkit-keyframes move-frames-33 {
  from {
    transform: translate3d(55vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -117vh, 0);
  }
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(55vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -117vh, 0);
  }
}
.circleContainer:nth-child(33) .circle {
  -webkit-animation-delay: 1877ms;
          animation-delay: 1877ms;
}
.circleContainer:nth-child(34) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-34;
          animation-name: move-frames-34;
  -webkit-animation-duration: 34140ms;
          animation-duration: 34140ms;
  -webkit-animation-delay: 7632ms;
          animation-delay: 7632ms;
}
@-webkit-keyframes move-frames-34 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(91vw, -125vh, 0);
  }
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(91vw, -125vh, 0);
  }
}
.circleContainer:nth-child(34) .circle {
  -webkit-animation-delay: 2545ms;
          animation-delay: 2545ms;
}
.circleContainer:nth-child(35) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-35;
          animation-name: move-frames-35;
  -webkit-animation-duration: 30499ms;
          animation-duration: 30499ms;
  -webkit-animation-delay: 15413ms;
          animation-delay: 15413ms;
}
@-webkit-keyframes move-frames-35 {
  from {
    transform: translate3d(50vw, 104vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(50vw, 104vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
.circleContainer:nth-child(35) .circle {
  -webkit-animation-delay: 2351ms;
          animation-delay: 2351ms;
}
.circleContainer:nth-child(36) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-36;
          animation-name: move-frames-36;
  -webkit-animation-duration: 29766ms;
          animation-duration: 29766ms;
  -webkit-animation-delay: 24741ms;
          animation-delay: 24741ms;
}
@-webkit-keyframes move-frames-36 {
  from {
    transform: translate3d(9vw, 101vh, 0);
  }
  to {
    transform: translate3d(80vw, -130vh, 0);
  }
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(9vw, 101vh, 0);
  }
  to {
    transform: translate3d(80vw, -130vh, 0);
  }
}
.circleContainer:nth-child(36) .circle {
  -webkit-animation-delay: 39ms;
          animation-delay: 39ms;
}
.circleContainer:nth-child(37) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-37;
          animation-name: move-frames-37;
  -webkit-animation-duration: 30499ms;
          animation-duration: 30499ms;
  -webkit-animation-delay: 23004ms;
          animation-delay: 23004ms;
}
@-webkit-keyframes move-frames-37 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }
  to {
    transform: translate3d(52vw, -105vh, 0);
  }
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }
  to {
    transform: translate3d(52vw, -105vh, 0);
  }
}
.circleContainer:nth-child(37) .circle {
  -webkit-animation-delay: 952ms;
          animation-delay: 952ms;
}
.circleContainer:nth-child(38) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-38;
          animation-name: move-frames-38;
  -webkit-animation-duration: 36950ms;
          animation-duration: 36950ms;
  -webkit-animation-delay: 13370ms;
          animation-delay: 13370ms;
}
@-webkit-keyframes move-frames-38 {
  from {
    transform: translate3d(90vw, 102vh, 0);
  }
  to {
    transform: translate3d(89vw, -127vh, 0);
  }
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(90vw, 102vh, 0);
  }
  to {
    transform: translate3d(89vw, -127vh, 0);
  }
}
.circleContainer:nth-child(38) .circle {
  -webkit-animation-delay: 3187ms;
          animation-delay: 3187ms;
}
.circleContainer:nth-child(39) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-39;
          animation-name: move-frames-39;
  -webkit-animation-duration: 35816ms;
          animation-duration: 35816ms;
  -webkit-animation-delay: 19071ms;
          animation-delay: 19071ms;
}
@-webkit-keyframes move-frames-39 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(37vw, -133vh, 0);
  }
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(37vw, -133vh, 0);
  }
}
.circleContainer:nth-child(39) .circle {
  -webkit-animation-delay: 1828ms;
          animation-delay: 1828ms;
}
.circleContainer:nth-child(40) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-40;
          animation-name: move-frames-40;
  -webkit-animation-duration: 34535ms;
          animation-duration: 34535ms;
  -webkit-animation-delay: 4088ms;
          animation-delay: 4088ms;
}
@-webkit-keyframes move-frames-40 {
  from {
    transform: translate3d(86vw, 108vh, 0);
  }
  to {
    transform: translate3d(55vw, -126vh, 0);
  }
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(86vw, 108vh, 0);
  }
  to {
    transform: translate3d(55vw, -126vh, 0);
  }
}
.circleContainer:nth-child(40) .circle {
  -webkit-animation-delay: 2189ms;
          animation-delay: 2189ms;
}
.circleContainer:nth-child(41) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-41;
          animation-name: move-frames-41;
  -webkit-animation-duration: 28830ms;
          animation-duration: 28830ms;
  -webkit-animation-delay: 34600ms;
          animation-delay: 34600ms;
}
@-webkit-keyframes move-frames-41 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(50vw, -131vh, 0);
  }
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(50vw, -131vh, 0);
  }
}
.circleContainer:nth-child(41) .circle {
  -webkit-animation-delay: 84ms;
          animation-delay: 84ms;
}
.circleContainer:nth-child(42) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-42;
          animation-name: move-frames-42;
  -webkit-animation-duration: 34291ms;
          animation-duration: 34291ms;
  -webkit-animation-delay: 27669ms;
          animation-delay: 27669ms;
}
@-webkit-keyframes move-frames-42 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(4vw, -127vh, 0);
  }
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(4vw, -127vh, 0);
  }
}
.circleContainer:nth-child(42) .circle {
  -webkit-animation-delay: 1823ms;
          animation-delay: 1823ms;
}
.circleContainer:nth-child(43) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-43;
          animation-name: move-frames-43;
  -webkit-animation-duration: 28775ms;
          animation-duration: 28775ms;
  -webkit-animation-delay: 5997ms;
          animation-delay: 5997ms;
}
@-webkit-keyframes move-frames-43 {
  from {
    transform: translate3d(65vw, 104vh, 0);
  }
  to {
    transform: translate3d(35vw, -108vh, 0);
  }
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(65vw, 104vh, 0);
  }
  to {
    transform: translate3d(35vw, -108vh, 0);
  }
}
.circleContainer:nth-child(43) .circle {
  -webkit-animation-delay: 798ms;
          animation-delay: 798ms;
}
.circleContainer:nth-child(44) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-44;
          animation-name: move-frames-44;
  -webkit-animation-duration: 33734ms;
          animation-duration: 33734ms;
  -webkit-animation-delay: 14810ms;
          animation-delay: 14810ms;
}
@-webkit-keyframes move-frames-44 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -106vh, 0);
  }
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -106vh, 0);
  }
}
.circleContainer:nth-child(44) .circle {
  -webkit-animation-delay: 1522ms;
          animation-delay: 1522ms;
}
.circleContainer:nth-child(45) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-45;
          animation-name: move-frames-45;
  -webkit-animation-duration: 35270ms;
          animation-duration: 35270ms;
  -webkit-animation-delay: 15066ms;
          animation-delay: 15066ms;
}
@-webkit-keyframes move-frames-45 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
.circleContainer:nth-child(45) .circle {
  -webkit-animation-delay: 3301ms;
          animation-delay: 3301ms;
}
.circleContainer:nth-child(46) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-46;
          animation-name: move-frames-46;
  -webkit-animation-duration: 31766ms;
          animation-duration: 31766ms;
  -webkit-animation-delay: 13115ms;
          animation-delay: 13115ms;
}
@-webkit-keyframes move-frames-46 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -134vh, 0);
  }
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -134vh, 0);
  }
}
.circleContainer:nth-child(46) .circle {
  -webkit-animation-delay: 3692ms;
          animation-delay: 3692ms;
}
.circleContainer:nth-child(47) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-47;
          animation-name: move-frames-47;
  -webkit-animation-duration: 32098ms;
          animation-duration: 32098ms;
  -webkit-animation-delay: 9368ms;
          animation-delay: 9368ms;
}
@-webkit-keyframes move-frames-47 {
  from {
    transform: translate3d(90vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -127vh, 0);
  }
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(90vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -127vh, 0);
  }
}
.circleContainer:nth-child(47) .circle {
  -webkit-animation-delay: 1283ms;
          animation-delay: 1283ms;
}
.circleContainer:nth-child(48) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-48;
          animation-name: move-frames-48;
  -webkit-animation-duration: 33936ms;
          animation-duration: 33936ms;
  -webkit-animation-delay: 24053ms;
          animation-delay: 24053ms;
}
@-webkit-keyframes move-frames-48 {
  from {
    transform: translate3d(42vw, 110vh, 0);
  }
  to {
    transform: translate3d(2vw, -133vh, 0);
  }
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(42vw, 110vh, 0);
  }
  to {
    transform: translate3d(2vw, -133vh, 0);
  }
}
.circleContainer:nth-child(48) .circle {
  -webkit-animation-delay: 3125ms;
          animation-delay: 3125ms;
}
.circleContainer:nth-child(49) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-49;
          animation-name: move-frames-49;
  -webkit-animation-duration: 34163ms;
          animation-duration: 34163ms;
  -webkit-animation-delay: 32206ms;
          animation-delay: 32206ms;
}
@-webkit-keyframes move-frames-49 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(27vw, -111vh, 0);
  }
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(27vw, -111vh, 0);
  }
}
.circleContainer:nth-child(49) .circle {
  -webkit-animation-delay: 257ms;
          animation-delay: 257ms;
}
.circleContainer:nth-child(50) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-50;
          animation-name: move-frames-50;
  -webkit-animation-duration: 31350ms;
          animation-duration: 31350ms;
  -webkit-animation-delay: 25880ms;
          animation-delay: 25880ms;
}
@-webkit-keyframes move-frames-50 {
  from {
    transform: translate3d(33vw, 107vh, 0);
  }
  to {
    transform: translate3d(65vw, -109vh, 0);
  }
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(33vw, 107vh, 0);
  }
  to {
    transform: translate3d(65vw, -109vh, 0);
  }
}
.circleContainer:nth-child(50) .circle {
  -webkit-animation-delay: 2790ms;
          animation-delay: 2790ms;
}
.circleContainer:nth-child(51) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-51;
          animation-name: move-frames-51;
  -webkit-animation-duration: 29188ms;
          animation-duration: 29188ms;
  -webkit-animation-delay: 5212ms;
          animation-delay: 5212ms;
}
@-webkit-keyframes move-frames-51 {
  from {
    transform: translate3d(73vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -127vh, 0);
  }
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(73vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -127vh, 0);
  }
}
.circleContainer:nth-child(51) .circle {
  -webkit-animation-delay: 3285ms;
          animation-delay: 3285ms;
}
.circleContainer:nth-child(52) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-52;
          animation-name: move-frames-52;
  -webkit-animation-duration: 35811ms;
          animation-duration: 35811ms;
  -webkit-animation-delay: 10258ms;
          animation-delay: 10258ms;
}
@-webkit-keyframes move-frames-52 {
  from {
    transform: translate3d(80vw, 109vh, 0);
  }
  to {
    transform: translate3d(82vw, -136vh, 0);
  }
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(80vw, 109vh, 0);
  }
  to {
    transform: translate3d(82vw, -136vh, 0);
  }
}
.circleContainer:nth-child(52) .circle {
  -webkit-animation-delay: 3428ms;
          animation-delay: 3428ms;
}
.circleContainer:nth-child(53) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-53;
          animation-name: move-frames-53;
  -webkit-animation-duration: 35651ms;
          animation-duration: 35651ms;
  -webkit-animation-delay: 6499ms;
          animation-delay: 6499ms;
}
@-webkit-keyframes move-frames-53 {
  from {
    transform: translate3d(20vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -104vh, 0);
  }
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(20vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -104vh, 0);
  }
}
.circleContainer:nth-child(53) .circle {
  -webkit-animation-delay: 3406ms;
          animation-delay: 3406ms;
}
.circleContainer:nth-child(54) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-54;
          animation-name: move-frames-54;
  -webkit-animation-duration: 32075ms;
          animation-duration: 32075ms;
  -webkit-animation-delay: 34513ms;
          animation-delay: 34513ms;
}
@-webkit-keyframes move-frames-54 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }
  to {
    transform: translate3d(46vw, -124vh, 0);
  }
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }
  to {
    transform: translate3d(46vw, -124vh, 0);
  }
}
.circleContainer:nth-child(54) .circle {
  -webkit-animation-delay: 787ms;
          animation-delay: 787ms;
}
.circleContainer:nth-child(55) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-55;
          animation-name: move-frames-55;
  -webkit-animation-duration: 33120ms;
          animation-duration: 33120ms;
  -webkit-animation-delay: 32449ms;
          animation-delay: 32449ms;
}
@-webkit-keyframes move-frames-55 {
  from {
    transform: translate3d(86vw, 102vh, 0);
  }
  to {
    transform: translate3d(11vw, -115vh, 0);
  }
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(86vw, 102vh, 0);
  }
  to {
    transform: translate3d(11vw, -115vh, 0);
  }
}
.circleContainer:nth-child(55) .circle {
  -webkit-animation-delay: 346ms;
          animation-delay: 346ms;
}
.circleContainer:nth-child(56) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-56;
          animation-name: move-frames-56;
  -webkit-animation-duration: 36289ms;
          animation-duration: 36289ms;
  -webkit-animation-delay: 11762ms;
          animation-delay: 11762ms;
}
@-webkit-keyframes move-frames-56 {
  from {
    transform: translate3d(61vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -117vh, 0);
  }
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(61vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -117vh, 0);
  }
}
.circleContainer:nth-child(56) .circle {
  -webkit-animation-delay: 1880ms;
          animation-delay: 1880ms;
}
.circleContainer:nth-child(57) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-57;
          animation-name: move-frames-57;
  -webkit-animation-duration: 31756ms;
          animation-duration: 31756ms;
  -webkit-animation-delay: 10516ms;
          animation-delay: 10516ms;
}
@-webkit-keyframes move-frames-57 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
.circleContainer:nth-child(57) .circle {
  -webkit-animation-delay: 530ms;
          animation-delay: 530ms;
}
.circleContainer:nth-child(58) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-58;
          animation-name: move-frames-58;
  -webkit-animation-duration: 31926ms;
          animation-duration: 31926ms;
  -webkit-animation-delay: 16556ms;
          animation-delay: 16556ms;
}
@-webkit-keyframes move-frames-58 {
  from {
    transform: translate3d(15vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -133vh, 0);
  }
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(15vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -133vh, 0);
  }
}
.circleContainer:nth-child(58) .circle {
  -webkit-animation-delay: 2035ms;
          animation-delay: 2035ms;
}
.circleContainer:nth-child(59) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-59;
          animation-name: move-frames-59;
  -webkit-animation-duration: 31893ms;
          animation-duration: 31893ms;
  -webkit-animation-delay: 6493ms;
          animation-delay: 6493ms;
}
@-webkit-keyframes move-frames-59 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }
  to {
    transform: translate3d(91vw, -120vh, 0);
  }
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }
  to {
    transform: translate3d(91vw, -120vh, 0);
  }
}
.circleContainer:nth-child(59) .circle {
  -webkit-animation-delay: 718ms;
          animation-delay: 718ms;
}
.circleContainer:nth-child(60) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-60;
          animation-name: move-frames-60;
  -webkit-animation-duration: 33492ms;
          animation-duration: 33492ms;
  -webkit-animation-delay: 28228ms;
          animation-delay: 28228ms;
}
@-webkit-keyframes move-frames-60 {
  from {
    transform: translate3d(25vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(25vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
.circleContainer:nth-child(60) .circle {
  -webkit-animation-delay: 829ms;
          animation-delay: 829ms;
}
.circleContainer:nth-child(61) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-61;
          animation-name: move-frames-61;
  -webkit-animation-duration: 29250ms;
          animation-duration: 29250ms;
  -webkit-animation-delay: 5473ms;
          animation-delay: 5473ms;
}
@-webkit-keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 107vh, 0);
  }
  to {
    transform: translate3d(98vw, -129vh, 0);
  }
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 107vh, 0);
  }
  to {
    transform: translate3d(98vw, -129vh, 0);
  }
}
.circleContainer:nth-child(61) .circle {
  -webkit-animation-delay: 2728ms;
          animation-delay: 2728ms;
}
.circleContainer:nth-child(62) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-62;
          animation-name: move-frames-62;
  -webkit-animation-duration: 30441ms;
          animation-duration: 30441ms;
  -webkit-animation-delay: 33005ms;
          animation-delay: 33005ms;
}
@-webkit-keyframes move-frames-62 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(28vw, -112vh, 0);
  }
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(28vw, -112vh, 0);
  }
}
.circleContainer:nth-child(62) .circle {
  -webkit-animation-delay: 957ms;
          animation-delay: 957ms;
}
.circleContainer:nth-child(63) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-63;
          animation-name: move-frames-63;
  -webkit-animation-duration: 35566ms;
          animation-duration: 35566ms;
  -webkit-animation-delay: 21659ms;
          animation-delay: 21659ms;
}
@-webkit-keyframes move-frames-63 {
  from {
    transform: translate3d(85vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -132vh, 0);
  }
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(85vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -132vh, 0);
  }
}
.circleContainer:nth-child(63) .circle {
  -webkit-animation-delay: 2583ms;
          animation-delay: 2583ms;
}
.circleContainer:nth-child(64) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-64;
          animation-name: move-frames-64;
  -webkit-animation-duration: 34073ms;
          animation-duration: 34073ms;
  -webkit-animation-delay: 22379ms;
          animation-delay: 22379ms;
}
@-webkit-keyframes move-frames-64 {
  from {
    transform: translate3d(58vw, 108vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(58vw, 108vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
.circleContainer:nth-child(64) .circle {
  -webkit-animation-delay: 3483ms;
          animation-delay: 3483ms;
}
.circleContainer:nth-child(65) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-65;
          animation-name: move-frames-65;
  -webkit-animation-duration: 34022ms;
          animation-duration: 34022ms;
  -webkit-animation-delay: 8695ms;
          animation-delay: 8695ms;
}
@-webkit-keyframes move-frames-65 {
  from {
    transform: translate3d(69vw, 103vh, 0);
  }
  to {
    transform: translate3d(60vw, -130vh, 0);
  }
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(69vw, 103vh, 0);
  }
  to {
    transform: translate3d(60vw, -130vh, 0);
  }
}
.circleContainer:nth-child(65) .circle {
  -webkit-animation-delay: 3296ms;
          animation-delay: 3296ms;
}
.circleContainer:nth-child(66) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-66;
          animation-name: move-frames-66;
  -webkit-animation-duration: 35131ms;
          animation-duration: 35131ms;
  -webkit-animation-delay: 12997ms;
          animation-delay: 12997ms;
}
@-webkit-keyframes move-frames-66 {
  from {
    transform: translate3d(61vw, 107vh, 0);
  }
  to {
    transform: translate3d(67vw, -122vh, 0);
  }
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(61vw, 107vh, 0);
  }
  to {
    transform: translate3d(67vw, -122vh, 0);
  }
}
.circleContainer:nth-child(66) .circle {
  -webkit-animation-delay: 2650ms;
          animation-delay: 2650ms;
}
.circleContainer:nth-child(67) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-67;
          animation-name: move-frames-67;
  -webkit-animation-duration: 28112ms;
          animation-duration: 28112ms;
  -webkit-animation-delay: 27068ms;
          animation-delay: 27068ms;
}
@-webkit-keyframes move-frames-67 {
  from {
    transform: translate3d(74vw, 101vh, 0);
  }
  to {
    transform: translate3d(15vw, -123vh, 0);
  }
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(74vw, 101vh, 0);
  }
  to {
    transform: translate3d(15vw, -123vh, 0);
  }
}
.circleContainer:nth-child(67) .circle {
  -webkit-animation-delay: 2931ms;
          animation-delay: 2931ms;
}
.circleContainer:nth-child(68) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-68;
          animation-name: move-frames-68;
  -webkit-animation-duration: 35803ms;
          animation-duration: 35803ms;
  -webkit-animation-delay: 28106ms;
          animation-delay: 28106ms;
}
@-webkit-keyframes move-frames-68 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }
  to {
    transform: translate3d(43vw, -108vh, 0);
  }
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }
  to {
    transform: translate3d(43vw, -108vh, 0);
  }
}
.circleContainer:nth-child(68) .circle {
  -webkit-animation-delay: 3386ms;
          animation-delay: 3386ms;
}
.circleContainer:nth-child(69) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-69;
          animation-name: move-frames-69;
  -webkit-animation-duration: 28855ms;
          animation-duration: 28855ms;
  -webkit-animation-delay: 30878ms;
          animation-delay: 30878ms;
}
@-webkit-keyframes move-frames-69 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(7vw, -116vh, 0);
  }
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(7vw, -116vh, 0);
  }
}
.circleContainer:nth-child(69) .circle {
  -webkit-animation-delay: 3263ms;
          animation-delay: 3263ms;
}
.circleContainer:nth-child(70) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-70;
          animation-name: move-frames-70;
  -webkit-animation-duration: 34241ms;
          animation-duration: 34241ms;
  -webkit-animation-delay: 28569ms;
          animation-delay: 28569ms;
}
@-webkit-keyframes move-frames-70 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
.circleContainer:nth-child(70) .circle {
  -webkit-animation-delay: 765ms;
          animation-delay: 765ms;
}
.circleContainer:nth-child(71) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-71;
          animation-name: move-frames-71;
  -webkit-animation-duration: 35165ms;
          animation-duration: 35165ms;
  -webkit-animation-delay: 31761ms;
          animation-delay: 31761ms;
}
@-webkit-keyframes move-frames-71 {
  from {
    transform: translate3d(57vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -140vh, 0);
  }
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(57vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -140vh, 0);
  }
}
.circleContainer:nth-child(71) .circle {
  -webkit-animation-delay: 1746ms;
          animation-delay: 1746ms;
}
.circleContainer:nth-child(72) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-72;
          animation-name: move-frames-72;
  -webkit-animation-duration: 29877ms;
          animation-duration: 29877ms;
  -webkit-animation-delay: 5027ms;
          animation-delay: 5027ms;
}
@-webkit-keyframes move-frames-72 {
  from {
    transform: translate3d(12vw, 106vh, 0);
  }
  to {
    transform: translate3d(32vw, -129vh, 0);
  }
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(12vw, 106vh, 0);
  }
  to {
    transform: translate3d(32vw, -129vh, 0);
  }
}
.circleContainer:nth-child(72) .circle {
  -webkit-animation-delay: 847ms;
          animation-delay: 847ms;
}
.circleContainer:nth-child(73) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-73;
          animation-name: move-frames-73;
  -webkit-animation-duration: 36453ms;
          animation-duration: 36453ms;
  -webkit-animation-delay: 35606ms;
          animation-delay: 35606ms;
}
@-webkit-keyframes move-frames-73 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -114vh, 0);
  }
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -114vh, 0);
  }
}
.circleContainer:nth-child(73) .circle {
  -webkit-animation-delay: 3575ms;
          animation-delay: 3575ms;
}
.circleContainer:nth-child(74) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-74;
          animation-name: move-frames-74;
  -webkit-animation-duration: 29636ms;
          animation-duration: 29636ms;
  -webkit-animation-delay: 13545ms;
          animation-delay: 13545ms;
}
@-webkit-keyframes move-frames-74 {
  from {
    transform: translate3d(24vw, 105vh, 0);
  }
  to {
    transform: translate3d(61vw, -107vh, 0);
  }
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(24vw, 105vh, 0);
  }
  to {
    transform: translate3d(61vw, -107vh, 0);
  }
}
.circleContainer:nth-child(74) .circle {
  -webkit-animation-delay: 708ms;
          animation-delay: 708ms;
}
.circleContainer:nth-child(75) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-75;
          animation-name: move-frames-75;
  -webkit-animation-duration: 29723ms;
          animation-duration: 29723ms;
  -webkit-animation-delay: 16884ms;
          animation-delay: 16884ms;
}
@-webkit-keyframes move-frames-75 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(8vw, -115vh, 0);
  }
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(8vw, -115vh, 0);
  }
}
.circleContainer:nth-child(75) .circle {
  -webkit-animation-delay: 734ms;
          animation-delay: 734ms;
}
.circleContainer:nth-child(76) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-76;
          animation-name: move-frames-76;
  -webkit-animation-duration: 35868ms;
          animation-duration: 35868ms;
  -webkit-animation-delay: 31703ms;
          animation-delay: 31703ms;
}
@-webkit-keyframes move-frames-76 {
  from {
    transform: translate3d(5vw, 110vh, 0);
  }
  to {
    transform: translate3d(8vw, -130vh, 0);
  }
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(5vw, 110vh, 0);
  }
  to {
    transform: translate3d(8vw, -130vh, 0);
  }
}
.circleContainer:nth-child(76) .circle {
  -webkit-animation-delay: 1449ms;
          animation-delay: 1449ms;
}
.circleContainer:nth-child(77) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-77;
          animation-name: move-frames-77;
  -webkit-animation-duration: 29724ms;
          animation-duration: 29724ms;
  -webkit-animation-delay: 31580ms;
          animation-delay: 31580ms;
}
@-webkit-keyframes move-frames-77 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(53vw, -131vh, 0);
  }
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(53vw, -131vh, 0);
  }
}
.circleContainer:nth-child(77) .circle {
  -webkit-animation-delay: 90ms;
          animation-delay: 90ms;
}
.circleContainer:nth-child(78) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-78;
          animation-name: move-frames-78;
  -webkit-animation-duration: 31350ms;
          animation-duration: 31350ms;
  -webkit-animation-delay: 6860ms;
          animation-delay: 6860ms;
}
@-webkit-keyframes move-frames-78 {
  from {
    transform: translate3d(80vw, 110vh, 0);
  }
  to {
    transform: translate3d(39vw, -136vh, 0);
  }
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(80vw, 110vh, 0);
  }
  to {
    transform: translate3d(39vw, -136vh, 0);
  }
}
.circleContainer:nth-child(78) .circle {
  -webkit-animation-delay: 192ms;
          animation-delay: 192ms;
}
.circleContainer:nth-child(79) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-79;
          animation-name: move-frames-79;
  -webkit-animation-duration: 34281ms;
          animation-duration: 34281ms;
  -webkit-animation-delay: 9217ms;
          animation-delay: 9217ms;
}
@-webkit-keyframes move-frames-79 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }
  to {
    transform: translate3d(80vw, -138vh, 0);
  }
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }
  to {
    transform: translate3d(80vw, -138vh, 0);
  }
}
.circleContainer:nth-child(79) .circle {
  -webkit-animation-delay: 2209ms;
          animation-delay: 2209ms;
}
.circleContainer:nth-child(80) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-80;
          animation-name: move-frames-80;
  -webkit-animation-duration: 33841ms;
          animation-duration: 33841ms;
  -webkit-animation-delay: 33919ms;
          animation-delay: 33919ms;
}
@-webkit-keyframes move-frames-80 {
  from {
    transform: translate3d(55vw, 109vh, 0);
  }
  to {
    transform: translate3d(20vw, -112vh, 0);
  }
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(55vw, 109vh, 0);
  }
  to {
    transform: translate3d(20vw, -112vh, 0);
  }
}
.circleContainer:nth-child(80) .circle {
  -webkit-animation-delay: 372ms;
          animation-delay: 372ms;
}
.circleContainer:nth-child(81) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-81;
          animation-name: move-frames-81;
  -webkit-animation-duration: 36317ms;
          animation-duration: 36317ms;
  -webkit-animation-delay: 21086ms;
          animation-delay: 21086ms;
}
@-webkit-keyframes move-frames-81 {
  from {
    transform: translate3d(53vw, 105vh, 0);
  }
  to {
    transform: translate3d(63vw, -115vh, 0);
  }
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(53vw, 105vh, 0);
  }
  to {
    transform: translate3d(63vw, -115vh, 0);
  }
}
.circleContainer:nth-child(81) .circle {
  -webkit-animation-delay: 2461ms;
          animation-delay: 2461ms;
}
.circleContainer:nth-child(82) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-82;
          animation-name: move-frames-82;
  -webkit-animation-duration: 33767ms;
          animation-duration: 33767ms;
  -webkit-animation-delay: 13596ms;
          animation-delay: 13596ms;
}
@-webkit-keyframes move-frames-82 {
  from {
    transform: translate3d(66vw, 110vh, 0);
  }
  to {
    transform: translate3d(6vw, -132vh, 0);
  }
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(66vw, 110vh, 0);
  }
  to {
    transform: translate3d(6vw, -132vh, 0);
  }
}
.circleContainer:nth-child(82) .circle {
  -webkit-animation-delay: 160ms;
          animation-delay: 160ms;
}
.circleContainer:nth-child(83) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-83;
          animation-name: move-frames-83;
  -webkit-animation-duration: 36961ms;
          animation-duration: 36961ms;
  -webkit-animation-delay: 25614ms;
          animation-delay: 25614ms;
}
@-webkit-keyframes move-frames-83 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -126vh, 0);
  }
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -126vh, 0);
  }
}
.circleContainer:nth-child(83) .circle {
  -webkit-animation-delay: 392ms;
          animation-delay: 392ms;
}
.circleContainer:nth-child(84) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-84;
          animation-name: move-frames-84;
  -webkit-animation-duration: 33720ms;
          animation-duration: 33720ms;
  -webkit-animation-delay: 34523ms;
          animation-delay: 34523ms;
}
@-webkit-keyframes move-frames-84 {
  from {
    transform: translate3d(64vw, 108vh, 0);
  }
  to {
    transform: translate3d(40vw, -114vh, 0);
  }
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(64vw, 108vh, 0);
  }
  to {
    transform: translate3d(40vw, -114vh, 0);
  }
}
.circleContainer:nth-child(84) .circle {
  -webkit-animation-delay: 3946ms;
          animation-delay: 3946ms;
}
.circleContainer:nth-child(85) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-85;
          animation-name: move-frames-85;
  -webkit-animation-duration: 33532ms;
          animation-duration: 33532ms;
  -webkit-animation-delay: 12309ms;
          animation-delay: 12309ms;
}
@-webkit-keyframes move-frames-85 {
  from {
    transform: translate3d(87vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -132vh, 0);
  }
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(87vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -132vh, 0);
  }
}
.circleContainer:nth-child(85) .circle {
  -webkit-animation-delay: 2756ms;
          animation-delay: 2756ms;
}
.circleContainer:nth-child(86) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-86;
          animation-name: move-frames-86;
  -webkit-animation-duration: 32932ms;
          animation-duration: 32932ms;
  -webkit-animation-delay: 17853ms;
          animation-delay: 17853ms;
}
@-webkit-keyframes move-frames-86 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -114vh, 0);
  }
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -114vh, 0);
  }
}
.circleContainer:nth-child(86) .circle {
  -webkit-animation-delay: 1445ms;
          animation-delay: 1445ms;
}
.circleContainer:nth-child(87) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-87;
          animation-name: move-frames-87;
  -webkit-animation-duration: 32648ms;
          animation-duration: 32648ms;
  -webkit-animation-delay: 27504ms;
          animation-delay: 27504ms;
}
@-webkit-keyframes move-frames-87 {
  from {
    transform: translate3d(19vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(19vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
.circleContainer:nth-child(87) .circle {
  -webkit-animation-delay: 3029ms;
          animation-delay: 3029ms;
}
.circleContainer:nth-child(88) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-88;
          animation-name: move-frames-88;
  -webkit-animation-duration: 28224ms;
          animation-duration: 28224ms;
  -webkit-animation-delay: 28116ms;
          animation-delay: 28116ms;
}
@-webkit-keyframes move-frames-88 {
  from {
    transform: translate3d(17vw, 104vh, 0);
  }
  to {
    transform: translate3d(87vw, -124vh, 0);
  }
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(17vw, 104vh, 0);
  }
  to {
    transform: translate3d(87vw, -124vh, 0);
  }
}
.circleContainer:nth-child(88) .circle {
  -webkit-animation-delay: 3610ms;
          animation-delay: 3610ms;
}
.circleContainer:nth-child(89) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-89;
          animation-name: move-frames-89;
  -webkit-animation-duration: 31082ms;
          animation-duration: 31082ms;
  -webkit-animation-delay: 17233ms;
          animation-delay: 17233ms;
}
@-webkit-keyframes move-frames-89 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
.circleContainer:nth-child(89) .circle {
  -webkit-animation-delay: 3456ms;
          animation-delay: 3456ms;
}
.circleContainer:nth-child(90) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-90;
          animation-name: move-frames-90;
  -webkit-animation-duration: 35086ms;
          animation-duration: 35086ms;
  -webkit-animation-delay: 26456ms;
          animation-delay: 26456ms;
}
@-webkit-keyframes move-frames-90 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(86vw, -122vh, 0);
  }
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(86vw, -122vh, 0);
  }
}
.circleContainer:nth-child(90) .circle {
  -webkit-animation-delay: 3090ms;
          animation-delay: 3090ms;
}
.circleContainer:nth-child(91) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-91;
          animation-name: move-frames-91;
  -webkit-animation-duration: 34702ms;
          animation-duration: 34702ms;
  -webkit-animation-delay: 19110ms;
          animation-delay: 19110ms;
}
@-webkit-keyframes move-frames-91 {
  from {
    transform: translate3d(2vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -116vh, 0);
  }
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(2vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -116vh, 0);
  }
}
.circleContainer:nth-child(91) .circle {
  -webkit-animation-delay: 2859ms;
          animation-delay: 2859ms;
}
.circleContainer:nth-child(92) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-92;
          animation-name: move-frames-92;
  -webkit-animation-duration: 33664ms;
          animation-duration: 33664ms;
  -webkit-animation-delay: 17751ms;
          animation-delay: 17751ms;
}
@-webkit-keyframes move-frames-92 {
  from {
    transform: translate3d(57vw, 107vh, 0);
  }
  to {
    transform: translate3d(87vw, -112vh, 0);
  }
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(57vw, 107vh, 0);
  }
  to {
    transform: translate3d(87vw, -112vh, 0);
  }
}
.circleContainer:nth-child(92) .circle {
  -webkit-animation-delay: 2411ms;
          animation-delay: 2411ms;
}
.circleContainer:nth-child(93) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-93;
          animation-name: move-frames-93;
  -webkit-animation-duration: 29224ms;
          animation-duration: 29224ms;
  -webkit-animation-delay: 23393ms;
          animation-delay: 23393ms;
}
@-webkit-keyframes move-frames-93 {
  from {
    transform: translate3d(24vw, 108vh, 0);
  }
  to {
    transform: translate3d(58vw, -123vh, 0);
  }
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(24vw, 108vh, 0);
  }
  to {
    transform: translate3d(58vw, -123vh, 0);
  }
}
.circleContainer:nth-child(93) .circle {
  -webkit-animation-delay: 155ms;
          animation-delay: 155ms;
}
.circleContainer:nth-child(94) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-94;
          animation-name: move-frames-94;
  -webkit-animation-duration: 34062ms;
          animation-duration: 34062ms;
  -webkit-animation-delay: 31621ms;
          animation-delay: 31621ms;
}
@-webkit-keyframes move-frames-94 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
.circleContainer:nth-child(94) .circle {
  -webkit-animation-delay: 3165ms;
          animation-delay: 3165ms;
}
.circleContainer:nth-child(95) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-95;
          animation-name: move-frames-95;
  -webkit-animation-duration: 33859ms;
          animation-duration: 33859ms;
  -webkit-animation-delay: 3147ms;
          animation-delay: 3147ms;
}
@-webkit-keyframes move-frames-95 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(31vw, -130vh, 0);
  }
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(31vw, -130vh, 0);
  }
}
.circleContainer:nth-child(95) .circle {
  -webkit-animation-delay: 517ms;
          animation-delay: 517ms;
}
.circleContainer:nth-child(96) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-96;
          animation-name: move-frames-96;
  -webkit-animation-duration: 31389ms;
          animation-duration: 31389ms;
  -webkit-animation-delay: 7593ms;
          animation-delay: 7593ms;
}
@-webkit-keyframes move-frames-96 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(60vw, -114vh, 0);
  }
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(60vw, -114vh, 0);
  }
}
.circleContainer:nth-child(96) .circle {
  -webkit-animation-delay: 1262ms;
          animation-delay: 1262ms;
}
.circleContainer:nth-child(97) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-97;
          animation-name: move-frames-97;
  -webkit-animation-duration: 32276ms;
          animation-duration: 32276ms;
  -webkit-animation-delay: 4493ms;
          animation-delay: 4493ms;
}
@-webkit-keyframes move-frames-97 {
  from {
    transform: translate3d(3vw, 102vh, 0);
  }
  to {
    transform: translate3d(77vw, -107vh, 0);
  }
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(3vw, 102vh, 0);
  }
  to {
    transform: translate3d(77vw, -107vh, 0);
  }
}
.circleContainer:nth-child(97) .circle {
  -webkit-animation-delay: 3764ms;
          animation-delay: 3764ms;
}
.circleContainer:nth-child(98) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-98;
          animation-name: move-frames-98;
  -webkit-animation-duration: 28309ms;
          animation-duration: 28309ms;
  -webkit-animation-delay: 15594ms;
          animation-delay: 15594ms;
}
@-webkit-keyframes move-frames-98 {
  from {
    transform: translate3d(80vw, 106vh, 0);
  }
  to {
    transform: translate3d(15vw, -112vh, 0);
  }
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(80vw, 106vh, 0);
  }
  to {
    transform: translate3d(15vw, -112vh, 0);
  }
}
.circleContainer:nth-child(98) .circle {
  -webkit-animation-delay: 1260ms;
          animation-delay: 1260ms;
}
.circleContainer:nth-child(99) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-99;
          animation-name: move-frames-99;
  -webkit-animation-duration: 28051ms;
          animation-duration: 28051ms;
  -webkit-animation-delay: 4351ms;
          animation-delay: 4351ms;
}
@-webkit-keyframes move-frames-99 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(19vw, -128vh, 0);
  }
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(19vw, -128vh, 0);
  }
}
.circleContainer:nth-child(99) .circle {
  -webkit-animation-delay: 3129ms;
          animation-delay: 3129ms;
}
.circleContainer:nth-child(100) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-100;
          animation-name: move-frames-100;
  -webkit-animation-duration: 30077ms;
          animation-duration: 30077ms;
  -webkit-animation-delay: 9619ms;
          animation-delay: 9619ms;
}
@-webkit-keyframes move-frames-100 {
  from {
    transform: translate3d(44vw, 110vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(44vw, 110vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
.circleContainer:nth-child(100) .circle {
  -webkit-animation-delay: 347ms;
          animation-delay: 347ms;
}
.circleContainer:nth-child(101) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-101;
          animation-name: move-frames-101;
  -webkit-animation-duration: 28091ms;
          animation-duration: 28091ms;
  -webkit-animation-delay: 373ms;
          animation-delay: 373ms;
}
@-webkit-keyframes move-frames-101 {
  from {
    transform: translate3d(24vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -120vh, 0);
  }
}
@keyframes move-frames-101 {
  from {
    transform: translate3d(24vw, 101vh, 0);
  }
  to {
    transform: translate3d(66vw, -120vh, 0);
  }
}
.circleContainer:nth-child(101) .circle {
  -webkit-animation-delay: 1968ms;
          animation-delay: 1968ms;
}
.circleContainer:nth-child(102) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-102;
          animation-name: move-frames-102;
  -webkit-animation-duration: 29041ms;
          animation-duration: 29041ms;
  -webkit-animation-delay: 26310ms;
          animation-delay: 26310ms;
}
@-webkit-keyframes move-frames-102 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(94vw, -108vh, 0);
  }
}
@keyframes move-frames-102 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(94vw, -108vh, 0);
  }
}
.circleContainer:nth-child(102) .circle {
  -webkit-animation-delay: 1616ms;
          animation-delay: 1616ms;
}
.circleContainer:nth-child(103) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-103;
          animation-name: move-frames-103;
  -webkit-animation-duration: 28524ms;
          animation-duration: 28524ms;
  -webkit-animation-delay: 23027ms;
          animation-delay: 23027ms;
}
@-webkit-keyframes move-frames-103 {
  from {
    transform: translate3d(91vw, 106vh, 0);
  }
  to {
    transform: translate3d(45vw, -107vh, 0);
  }
}
@keyframes move-frames-103 {
  from {
    transform: translate3d(91vw, 106vh, 0);
  }
  to {
    transform: translate3d(45vw, -107vh, 0);
  }
}
.circleContainer:nth-child(103) .circle {
  -webkit-animation-delay: 3295ms;
          animation-delay: 3295ms;
}
.circleContainer:nth-child(104) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-104;
          animation-name: move-frames-104;
  -webkit-animation-duration: 35568ms;
          animation-duration: 35568ms;
  -webkit-animation-delay: 33434ms;
          animation-delay: 33434ms;
}
@-webkit-keyframes move-frames-104 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(52vw, -130vh, 0);
  }
}
@keyframes move-frames-104 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(52vw, -130vh, 0);
  }
}
.circleContainer:nth-child(104) .circle {
  -webkit-animation-delay: 2159ms;
          animation-delay: 2159ms;
}
.circleContainer:nth-child(105) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-105;
          animation-name: move-frames-105;
  -webkit-animation-duration: 31613ms;
          animation-duration: 31613ms;
  -webkit-animation-delay: 23209ms;
          animation-delay: 23209ms;
}
@-webkit-keyframes move-frames-105 {
  from {
    transform: translate3d(45vw, 105vh, 0);
  }
  to {
    transform: translate3d(1vw, -130vh, 0);
  }
}
@keyframes move-frames-105 {
  from {
    transform: translate3d(45vw, 105vh, 0);
  }
  to {
    transform: translate3d(1vw, -130vh, 0);
  }
}
.circleContainer:nth-child(105) .circle {
  -webkit-animation-delay: 3938ms;
          animation-delay: 3938ms;
}
.circleContainer:nth-child(106) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-106;
          animation-name: move-frames-106;
  -webkit-animation-duration: 28395ms;
          animation-duration: 28395ms;
  -webkit-animation-delay: 1837ms;
          animation-delay: 1837ms;
}
@-webkit-keyframes move-frames-106 {
  from {
    transform: translate3d(86vw, 108vh, 0);
  }
  to {
    transform: translate3d(77vw, -120vh, 0);
  }
}
@keyframes move-frames-106 {
  from {
    transform: translate3d(86vw, 108vh, 0);
  }
  to {
    transform: translate3d(77vw, -120vh, 0);
  }
}
.circleContainer:nth-child(106) .circle {
  -webkit-animation-delay: 2985ms;
          animation-delay: 2985ms;
}
.circleContainer:nth-child(107) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-107;
          animation-name: move-frames-107;
  -webkit-animation-duration: 30056ms;
          animation-duration: 30056ms;
  -webkit-animation-delay: 9624ms;
          animation-delay: 9624ms;
}
@-webkit-keyframes move-frames-107 {
  from {
    transform: translate3d(40vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -130vh, 0);
  }
}
@keyframes move-frames-107 {
  from {
    transform: translate3d(40vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -130vh, 0);
  }
}
.circleContainer:nth-child(107) .circle {
  -webkit-animation-delay: 1266ms;
          animation-delay: 1266ms;
}
.circleContainer:nth-child(108) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-108;
          animation-name: move-frames-108;
  -webkit-animation-duration: 34956ms;
          animation-duration: 34956ms;
  -webkit-animation-delay: 29531ms;
          animation-delay: 29531ms;
}
@-webkit-keyframes move-frames-108 {
  from {
    transform: translate3d(35vw, 106vh, 0);
  }
  to {
    transform: translate3d(20vw, -121vh, 0);
  }
}
@keyframes move-frames-108 {
  from {
    transform: translate3d(35vw, 106vh, 0);
  }
  to {
    transform: translate3d(20vw, -121vh, 0);
  }
}
.circleContainer:nth-child(108) .circle {
  -webkit-animation-delay: 3381ms;
          animation-delay: 3381ms;
}
.circleContainer:nth-child(109) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-109;
          animation-name: move-frames-109;
  -webkit-animation-duration: 33869ms;
          animation-duration: 33869ms;
  -webkit-animation-delay: 26923ms;
          animation-delay: 26923ms;
}
@-webkit-keyframes move-frames-109 {
  from {
    transform: translate3d(40vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -114vh, 0);
  }
}
@keyframes move-frames-109 {
  from {
    transform: translate3d(40vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -114vh, 0);
  }
}
.circleContainer:nth-child(109) .circle {
  -webkit-animation-delay: 3862ms;
          animation-delay: 3862ms;
}
.circleContainer:nth-child(110) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-110;
          animation-name: move-frames-110;
  -webkit-animation-duration: 31041ms;
          animation-duration: 31041ms;
  -webkit-animation-delay: 24321ms;
          animation-delay: 24321ms;
}
@-webkit-keyframes move-frames-110 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }
  to {
    transform: translate3d(1vw, -121vh, 0);
  }
}
@keyframes move-frames-110 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }
  to {
    transform: translate3d(1vw, -121vh, 0);
  }
}
.circleContainer:nth-child(110) .circle {
  -webkit-animation-delay: 3434ms;
          animation-delay: 3434ms;
}
.circleContainer:nth-child(111) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-111;
          animation-name: move-frames-111;
  -webkit-animation-duration: 32046ms;
          animation-duration: 32046ms;
  -webkit-animation-delay: 2519ms;
          animation-delay: 2519ms;
}
@-webkit-keyframes move-frames-111 {
  from {
    transform: translate3d(11vw, 108vh, 0);
  }
  to {
    transform: translate3d(76vw, -126vh, 0);
  }
}
@keyframes move-frames-111 {
  from {
    transform: translate3d(11vw, 108vh, 0);
  }
  to {
    transform: translate3d(76vw, -126vh, 0);
  }
}
.circleContainer:nth-child(111) .circle {
  -webkit-animation-delay: 2062ms;
          animation-delay: 2062ms;
}
.circleContainer:nth-child(112) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-112;
          animation-name: move-frames-112;
  -webkit-animation-duration: 28461ms;
          animation-duration: 28461ms;
  -webkit-animation-delay: 3003ms;
          animation-delay: 3003ms;
}
@-webkit-keyframes move-frames-112 {
  from {
    transform: translate3d(93vw, 106vh, 0);
  }
  to {
    transform: translate3d(60vw, -128vh, 0);
  }
}
@keyframes move-frames-112 {
  from {
    transform: translate3d(93vw, 106vh, 0);
  }
  to {
    transform: translate3d(60vw, -128vh, 0);
  }
}
.circleContainer:nth-child(112) .circle {
  -webkit-animation-delay: 3305ms;
          animation-delay: 3305ms;
}
.circleContainer:nth-child(113) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-113;
          animation-name: move-frames-113;
  -webkit-animation-duration: 32369ms;
          animation-duration: 32369ms;
  -webkit-animation-delay: 20673ms;
          animation-delay: 20673ms;
}
@-webkit-keyframes move-frames-113 {
  from {
    transform: translate3d(25vw, 109vh, 0);
  }
  to {
    transform: translate3d(87vw, -115vh, 0);
  }
}
@keyframes move-frames-113 {
  from {
    transform: translate3d(25vw, 109vh, 0);
  }
  to {
    transform: translate3d(87vw, -115vh, 0);
  }
}
.circleContainer:nth-child(113) .circle {
  -webkit-animation-delay: 3477ms;
          animation-delay: 3477ms;
}
.circleContainer:nth-child(114) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-114;
          animation-name: move-frames-114;
  -webkit-animation-duration: 32055ms;
          animation-duration: 32055ms;
  -webkit-animation-delay: 10102ms;
          animation-delay: 10102ms;
}
@-webkit-keyframes move-frames-114 {
  from {
    transform: translate3d(19vw, 103vh, 0);
  }
  to {
    transform: translate3d(75vw, -127vh, 0);
  }
}
@keyframes move-frames-114 {
  from {
    transform: translate3d(19vw, 103vh, 0);
  }
  to {
    transform: translate3d(75vw, -127vh, 0);
  }
}
.circleContainer:nth-child(114) .circle {
  -webkit-animation-delay: 241ms;
          animation-delay: 241ms;
}
.circleContainer:nth-child(115) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-115;
          animation-name: move-frames-115;
  -webkit-animation-duration: 30550ms;
          animation-duration: 30550ms;
  -webkit-animation-delay: 19456ms;
          animation-delay: 19456ms;
}
@-webkit-keyframes move-frames-115 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(21vw, -110vh, 0);
  }
}
@keyframes move-frames-115 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(21vw, -110vh, 0);
  }
}
.circleContainer:nth-child(115) .circle {
  -webkit-animation-delay: 2341ms;
          animation-delay: 2341ms;
}
.circleContainer:nth-child(116) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-116;
          animation-name: move-frames-116;
  -webkit-animation-duration: 33093ms;
          animation-duration: 33093ms;
  -webkit-animation-delay: 18211ms;
          animation-delay: 18211ms;
}
@-webkit-keyframes move-frames-116 {
  from {
    transform: translate3d(22vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -121vh, 0);
  }
}
@keyframes move-frames-116 {
  from {
    transform: translate3d(22vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -121vh, 0);
  }
}
.circleContainer:nth-child(116) .circle {
  -webkit-animation-delay: 3116ms;
          animation-delay: 3116ms;
}
.circleContainer:nth-child(117) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-117;
          animation-name: move-frames-117;
  -webkit-animation-duration: 28566ms;
          animation-duration: 28566ms;
  -webkit-animation-delay: 20122ms;
          animation-delay: 20122ms;
}
@-webkit-keyframes move-frames-117 {
  from {
    transform: translate3d(23vw, 105vh, 0);
  }
  to {
    transform: translate3d(77vw, -131vh, 0);
  }
}
@keyframes move-frames-117 {
  from {
    transform: translate3d(23vw, 105vh, 0);
  }
  to {
    transform: translate3d(77vw, -131vh, 0);
  }
}
.circleContainer:nth-child(117) .circle {
  -webkit-animation-delay: 1504ms;
          animation-delay: 1504ms;
}
.circleContainer:nth-child(118) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-118;
          animation-name: move-frames-118;
  -webkit-animation-duration: 31643ms;
          animation-duration: 31643ms;
  -webkit-animation-delay: 14438ms;
          animation-delay: 14438ms;
}
@-webkit-keyframes move-frames-118 {
  from {
    transform: translate3d(2vw, 107vh, 0);
  }
  to {
    transform: translate3d(55vw, -131vh, 0);
  }
}
@keyframes move-frames-118 {
  from {
    transform: translate3d(2vw, 107vh, 0);
  }
  to {
    transform: translate3d(55vw, -131vh, 0);
  }
}
.circleContainer:nth-child(118) .circle {
  -webkit-animation-delay: 2560ms;
          animation-delay: 2560ms;
}
.circleContainer:nth-child(119) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-119;
          animation-name: move-frames-119;
  -webkit-animation-duration: 29191ms;
          animation-duration: 29191ms;
  -webkit-animation-delay: 15049ms;
          animation-delay: 15049ms;
}
@-webkit-keyframes move-frames-119 {
  from {
    transform: translate3d(74vw, 110vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
@keyframes move-frames-119 {
  from {
    transform: translate3d(74vw, 110vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
.circleContainer:nth-child(119) .circle {
  -webkit-animation-delay: 3716ms;
          animation-delay: 3716ms;
}
.circleContainer:nth-child(120) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-120;
          animation-name: move-frames-120;
  -webkit-animation-duration: 28377ms;
          animation-duration: 28377ms;
  -webkit-animation-delay: 5188ms;
          animation-delay: 5188ms;
}
@-webkit-keyframes move-frames-120 {
  from {
    transform: translate3d(58vw, 106vh, 0);
  }
  to {
    transform: translate3d(47vw, -120vh, 0);
  }
}
@keyframes move-frames-120 {
  from {
    transform: translate3d(58vw, 106vh, 0);
  }
  to {
    transform: translate3d(47vw, -120vh, 0);
  }
}
.circleContainer:nth-child(120) .circle {
  -webkit-animation-delay: 2590ms;
          animation-delay: 2590ms;
}
.circleContainer:nth-child(121) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-121;
          animation-name: move-frames-121;
  -webkit-animation-duration: 33547ms;
          animation-duration: 33547ms;
  -webkit-animation-delay: 36672ms;
          animation-delay: 36672ms;
}
@-webkit-keyframes move-frames-121 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(88vw, -122vh, 0);
  }
}
@keyframes move-frames-121 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(88vw, -122vh, 0);
  }
}
.circleContainer:nth-child(121) .circle {
  -webkit-animation-delay: 3622ms;
          animation-delay: 3622ms;
}
.circleContainer:nth-child(122) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-122;
          animation-name: move-frames-122;
  -webkit-animation-duration: 36785ms;
          animation-duration: 36785ms;
  -webkit-animation-delay: 22112ms;
          animation-delay: 22112ms;
}
@-webkit-keyframes move-frames-122 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(59vw, -115vh, 0);
  }
}
@keyframes move-frames-122 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(59vw, -115vh, 0);
  }
}
.circleContainer:nth-child(122) .circle {
  -webkit-animation-delay: 3177ms;
          animation-delay: 3177ms;
}
.circleContainer:nth-child(123) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-123;
          animation-name: move-frames-123;
  -webkit-animation-duration: 31827ms;
          animation-duration: 31827ms;
  -webkit-animation-delay: 1342ms;
          animation-delay: 1342ms;
}
@-webkit-keyframes move-frames-123 {
  from {
    transform: translate3d(91vw, 109vh, 0);
  }
  to {
    transform: translate3d(75vw, -113vh, 0);
  }
}
@keyframes move-frames-123 {
  from {
    transform: translate3d(91vw, 109vh, 0);
  }
  to {
    transform: translate3d(75vw, -113vh, 0);
  }
}
.circleContainer:nth-child(123) .circle {
  -webkit-animation-delay: 349ms;
          animation-delay: 349ms;
}
.circleContainer:nth-child(124) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-124;
          animation-name: move-frames-124;
  -webkit-animation-duration: 31957ms;
          animation-duration: 31957ms;
  -webkit-animation-delay: 17177ms;
          animation-delay: 17177ms;
}
@-webkit-keyframes move-frames-124 {
  from {
    transform: translate3d(83vw, 105vh, 0);
  }
  to {
    transform: translate3d(41vw, -106vh, 0);
  }
}
@keyframes move-frames-124 {
  from {
    transform: translate3d(83vw, 105vh, 0);
  }
  to {
    transform: translate3d(41vw, -106vh, 0);
  }
}
.circleContainer:nth-child(124) .circle {
  -webkit-animation-delay: 3788ms;
          animation-delay: 3788ms;
}
.circleContainer:nth-child(125) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-125;
          animation-name: move-frames-125;
  -webkit-animation-duration: 30550ms;
          animation-duration: 30550ms;
  -webkit-animation-delay: 989ms;
          animation-delay: 989ms;
}
@-webkit-keyframes move-frames-125 {
  from {
    transform: translate3d(5vw, 101vh, 0);
  }
  to {
    transform: translate3d(11vw, -117vh, 0);
  }
}
@keyframes move-frames-125 {
  from {
    transform: translate3d(5vw, 101vh, 0);
  }
  to {
    transform: translate3d(11vw, -117vh, 0);
  }
}
.circleContainer:nth-child(125) .circle {
  -webkit-animation-delay: 1904ms;
          animation-delay: 1904ms;
}
.circleContainer:nth-child(126) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-126;
          animation-name: move-frames-126;
  -webkit-animation-duration: 31988ms;
          animation-duration: 31988ms;
  -webkit-animation-delay: 26103ms;
          animation-delay: 26103ms;
}
@-webkit-keyframes move-frames-126 {
  from {
    transform: translate3d(1vw, 109vh, 0);
  }
  to {
    transform: translate3d(30vw, -115vh, 0);
  }
}
@keyframes move-frames-126 {
  from {
    transform: translate3d(1vw, 109vh, 0);
  }
  to {
    transform: translate3d(30vw, -115vh, 0);
  }
}
.circleContainer:nth-child(126) .circle {
  -webkit-animation-delay: 3718ms;
          animation-delay: 3718ms;
}
.circleContainer:nth-child(127) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-127;
          animation-name: move-frames-127;
  -webkit-animation-duration: 36395ms;
          animation-duration: 36395ms;
  -webkit-animation-delay: 29360ms;
          animation-delay: 29360ms;
}
@-webkit-keyframes move-frames-127 {
  from {
    transform: translate3d(24vw, 104vh, 0);
  }
  to {
    transform: translate3d(76vw, -114vh, 0);
  }
}
@keyframes move-frames-127 {
  from {
    transform: translate3d(24vw, 104vh, 0);
  }
  to {
    transform: translate3d(76vw, -114vh, 0);
  }
}
.circleContainer:nth-child(127) .circle {
  -webkit-animation-delay: 67ms;
          animation-delay: 67ms;
}
.circleContainer:nth-child(128) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-128;
          animation-name: move-frames-128;
  -webkit-animation-duration: 36739ms;
          animation-duration: 36739ms;
  -webkit-animation-delay: 10867ms;
          animation-delay: 10867ms;
}
@-webkit-keyframes move-frames-128 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(28vw, -118vh, 0);
  }
}
@keyframes move-frames-128 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(28vw, -118vh, 0);
  }
}
.circleContainer:nth-child(128) .circle {
  -webkit-animation-delay: 1310ms;
          animation-delay: 1310ms;
}
.circleContainer:nth-child(129) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-129;
          animation-name: move-frames-129;
  -webkit-animation-duration: 31278ms;
          animation-duration: 31278ms;
  -webkit-animation-delay: 29213ms;
          animation-delay: 29213ms;
}
@-webkit-keyframes move-frames-129 {
  from {
    transform: translate3d(57vw, 107vh, 0);
  }
  to {
    transform: translate3d(40vw, -131vh, 0);
  }
}
@keyframes move-frames-129 {
  from {
    transform: translate3d(57vw, 107vh, 0);
  }
  to {
    transform: translate3d(40vw, -131vh, 0);
  }
}
.circleContainer:nth-child(129) .circle {
  -webkit-animation-delay: 3074ms;
          animation-delay: 3074ms;
}
.circleContainer:nth-child(130) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-130;
          animation-name: move-frames-130;
  -webkit-animation-duration: 31552ms;
          animation-duration: 31552ms;
  -webkit-animation-delay: 23135ms;
          animation-delay: 23135ms;
}
@-webkit-keyframes move-frames-130 {
  from {
    transform: translate3d(41vw, 102vh, 0);
  }
  to {
    transform: translate3d(12vw, -123vh, 0);
  }
}
@keyframes move-frames-130 {
  from {
    transform: translate3d(41vw, 102vh, 0);
  }
  to {
    transform: translate3d(12vw, -123vh, 0);
  }
}
.circleContainer:nth-child(130) .circle {
  -webkit-animation-delay: 1694ms;
          animation-delay: 1694ms;
}
.circleContainer:nth-child(131) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-131;
          animation-name: move-frames-131;
  -webkit-animation-duration: 33220ms;
          animation-duration: 33220ms;
  -webkit-animation-delay: 32967ms;
          animation-delay: 32967ms;
}
@-webkit-keyframes move-frames-131 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -123vh, 0);
  }
}
@keyframes move-frames-131 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -123vh, 0);
  }
}
.circleContainer:nth-child(131) .circle {
  -webkit-animation-delay: 720ms;
          animation-delay: 720ms;
}
.circleContainer:nth-child(132) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-132;
          animation-name: move-frames-132;
  -webkit-animation-duration: 36097ms;
          animation-duration: 36097ms;
  -webkit-animation-delay: 12303ms;
          animation-delay: 12303ms;
}
@-webkit-keyframes move-frames-132 {
  from {
    transform: translate3d(71vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
@keyframes move-frames-132 {
  from {
    transform: translate3d(71vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
.circleContainer:nth-child(132) .circle {
  -webkit-animation-delay: 1201ms;
          animation-delay: 1201ms;
}
.circleContainer:nth-child(133) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-133;
          animation-name: move-frames-133;
  -webkit-animation-duration: 28219ms;
          animation-duration: 28219ms;
  -webkit-animation-delay: 7320ms;
          animation-delay: 7320ms;
}
@-webkit-keyframes move-frames-133 {
  from {
    transform: translate3d(64vw, 101vh, 0);
  }
  to {
    transform: translate3d(77vw, -118vh, 0);
  }
}
@keyframes move-frames-133 {
  from {
    transform: translate3d(64vw, 101vh, 0);
  }
  to {
    transform: translate3d(77vw, -118vh, 0);
  }
}
.circleContainer:nth-child(133) .circle {
  -webkit-animation-delay: 2049ms;
          animation-delay: 2049ms;
}
.circleContainer:nth-child(134) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-134;
          animation-name: move-frames-134;
  -webkit-animation-duration: 29509ms;
          animation-duration: 29509ms;
  -webkit-animation-delay: 27745ms;
          animation-delay: 27745ms;
}
@-webkit-keyframes move-frames-134 {
  from {
    transform: translate3d(34vw, 109vh, 0);
  }
  to {
    transform: translate3d(61vw, -134vh, 0);
  }
}
@keyframes move-frames-134 {
  from {
    transform: translate3d(34vw, 109vh, 0);
  }
  to {
    transform: translate3d(61vw, -134vh, 0);
  }
}
.circleContainer:nth-child(134) .circle {
  -webkit-animation-delay: 381ms;
          animation-delay: 381ms;
}
.circleContainer:nth-child(135) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-135;
          animation-name: move-frames-135;
  -webkit-animation-duration: 32891ms;
          animation-duration: 32891ms;
  -webkit-animation-delay: 28230ms;
          animation-delay: 28230ms;
}
@-webkit-keyframes move-frames-135 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -119vh, 0);
  }
}
@keyframes move-frames-135 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -119vh, 0);
  }
}
.circleContainer:nth-child(135) .circle {
  -webkit-animation-delay: 3784ms;
          animation-delay: 3784ms;
}
.circleContainer:nth-child(136) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-136;
          animation-name: move-frames-136;
  -webkit-animation-duration: 33240ms;
          animation-duration: 33240ms;
  -webkit-animation-delay: 8169ms;
          animation-delay: 8169ms;
}
@-webkit-keyframes move-frames-136 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -133vh, 0);
  }
}
@keyframes move-frames-136 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -133vh, 0);
  }
}
.circleContainer:nth-child(136) .circle {
  -webkit-animation-delay: 3680ms;
          animation-delay: 3680ms;
}
.circleContainer:nth-child(137) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-137;
          animation-name: move-frames-137;
  -webkit-animation-duration: 29752ms;
          animation-duration: 29752ms;
  -webkit-animation-delay: 13406ms;
          animation-delay: 13406ms;
}
@-webkit-keyframes move-frames-137 {
  from {
    transform: translate3d(48vw, 102vh, 0);
  }
  to {
    transform: translate3d(44vw, -131vh, 0);
  }
}
@keyframes move-frames-137 {
  from {
    transform: translate3d(48vw, 102vh, 0);
  }
  to {
    transform: translate3d(44vw, -131vh, 0);
  }
}
.circleContainer:nth-child(137) .circle {
  -webkit-animation-delay: 290ms;
          animation-delay: 290ms;
}
.circleContainer:nth-child(138) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-138;
          animation-name: move-frames-138;
  -webkit-animation-duration: 31137ms;
          animation-duration: 31137ms;
  -webkit-animation-delay: 14642ms;
          animation-delay: 14642ms;
}
@-webkit-keyframes move-frames-138 {
  from {
    transform: translate3d(41vw, 108vh, 0);
  }
  to {
    transform: translate3d(43vw, -135vh, 0);
  }
}
@keyframes move-frames-138 {
  from {
    transform: translate3d(41vw, 108vh, 0);
  }
  to {
    transform: translate3d(43vw, -135vh, 0);
  }
}
.circleContainer:nth-child(138) .circle {
  -webkit-animation-delay: 1381ms;
          animation-delay: 1381ms;
}
.circleContainer:nth-child(139) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-139;
          animation-name: move-frames-139;
  -webkit-animation-duration: 36055ms;
          animation-duration: 36055ms;
  -webkit-animation-delay: 2556ms;
          animation-delay: 2556ms;
}
@-webkit-keyframes move-frames-139 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(24vw, -115vh, 0);
  }
}
@keyframes move-frames-139 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(24vw, -115vh, 0);
  }
}
.circleContainer:nth-child(139) .circle {
  -webkit-animation-delay: 2841ms;
          animation-delay: 2841ms;
}
.circleContainer:nth-child(140) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-140;
          animation-name: move-frames-140;
  -webkit-animation-duration: 31148ms;
          animation-duration: 31148ms;
  -webkit-animation-delay: 31059ms;
          animation-delay: 31059ms;
}
@-webkit-keyframes move-frames-140 {
  from {
    transform: translate3d(67vw, 107vh, 0);
  }
  to {
    transform: translate3d(12vw, -136vh, 0);
  }
}
@keyframes move-frames-140 {
  from {
    transform: translate3d(67vw, 107vh, 0);
  }
  to {
    transform: translate3d(12vw, -136vh, 0);
  }
}
.circleContainer:nth-child(140) .circle {
  -webkit-animation-delay: 3452ms;
          animation-delay: 3452ms;
}
.circleContainer:nth-child(141) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-141;
          animation-name: move-frames-141;
  -webkit-animation-duration: 30281ms;
          animation-duration: 30281ms;
  -webkit-animation-delay: 11851ms;
          animation-delay: 11851ms;
}
@-webkit-keyframes move-frames-141 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -115vh, 0);
  }
}
@keyframes move-frames-141 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -115vh, 0);
  }
}
.circleContainer:nth-child(141) .circle {
  -webkit-animation-delay: 289ms;
          animation-delay: 289ms;
}
.circleContainer:nth-child(142) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-142;
          animation-name: move-frames-142;
  -webkit-animation-duration: 36949ms;
          animation-duration: 36949ms;
  -webkit-animation-delay: 21409ms;
          animation-delay: 21409ms;
}
@-webkit-keyframes move-frames-142 {
  from {
    transform: translate3d(75vw, 103vh, 0);
  }
  to {
    transform: translate3d(67vw, -114vh, 0);
  }
}
@keyframes move-frames-142 {
  from {
    transform: translate3d(75vw, 103vh, 0);
  }
  to {
    transform: translate3d(67vw, -114vh, 0);
  }
}
.circleContainer:nth-child(142) .circle {
  -webkit-animation-delay: 568ms;
          animation-delay: 568ms;
}
.circleContainer:nth-child(143) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-143;
          animation-name: move-frames-143;
  -webkit-animation-duration: 31499ms;
          animation-duration: 31499ms;
  -webkit-animation-delay: 20592ms;
          animation-delay: 20592ms;
}
@-webkit-keyframes move-frames-143 {
  from {
    transform: translate3d(97vw, 108vh, 0);
  }
  to {
    transform: translate3d(63vw, -137vh, 0);
  }
}
@keyframes move-frames-143 {
  from {
    transform: translate3d(97vw, 108vh, 0);
  }
  to {
    transform: translate3d(63vw, -137vh, 0);
  }
}
.circleContainer:nth-child(143) .circle {
  -webkit-animation-delay: 259ms;
          animation-delay: 259ms;
}
.circleContainer:nth-child(144) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-144;
          animation-name: move-frames-144;
  -webkit-animation-duration: 36654ms;
          animation-duration: 36654ms;
  -webkit-animation-delay: 4943ms;
          animation-delay: 4943ms;
}
@-webkit-keyframes move-frames-144 {
  from {
    transform: translate3d(2vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -135vh, 0);
  }
}
@keyframes move-frames-144 {
  from {
    transform: translate3d(2vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -135vh, 0);
  }
}
.circleContainer:nth-child(144) .circle {
  -webkit-animation-delay: 3210ms;
          animation-delay: 3210ms;
}
.circleContainer:nth-child(145) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-145;
          animation-name: move-frames-145;
  -webkit-animation-duration: 28633ms;
          animation-duration: 28633ms;
  -webkit-animation-delay: 16459ms;
          animation-delay: 16459ms;
}
@-webkit-keyframes move-frames-145 {
  from {
    transform: translate3d(25vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -133vh, 0);
  }
}
@keyframes move-frames-145 {
  from {
    transform: translate3d(25vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -133vh, 0);
  }
}
.circleContainer:nth-child(145) .circle {
  -webkit-animation-delay: 3328ms;
          animation-delay: 3328ms;
}
.circleContainer:nth-child(146) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-146;
          animation-name: move-frames-146;
  -webkit-animation-duration: 31046ms;
          animation-duration: 31046ms;
  -webkit-animation-delay: 25141ms;
          animation-delay: 25141ms;
}
@-webkit-keyframes move-frames-146 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(92vw, -113vh, 0);
  }
}
@keyframes move-frames-146 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(92vw, -113vh, 0);
  }
}
.circleContainer:nth-child(146) .circle {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}
.circleContainer:nth-child(147) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-147;
          animation-name: move-frames-147;
  -webkit-animation-duration: 32793ms;
          animation-duration: 32793ms;
  -webkit-animation-delay: 33322ms;
          animation-delay: 33322ms;
}
@-webkit-keyframes move-frames-147 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(41vw, -129vh, 0);
  }
}
@keyframes move-frames-147 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(41vw, -129vh, 0);
  }
}
.circleContainer:nth-child(147) .circle {
  -webkit-animation-delay: 2397ms;
          animation-delay: 2397ms;
}
.circleContainer:nth-child(148) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-148;
          animation-name: move-frames-148;
  -webkit-animation-duration: 31055ms;
          animation-duration: 31055ms;
  -webkit-animation-delay: 24734ms;
          animation-delay: 24734ms;
}
@-webkit-keyframes move-frames-148 {
  from {
    transform: translate3d(1vw, 104vh, 0);
  }
  to {
    transform: translate3d(93vw, -133vh, 0);
  }
}
@keyframes move-frames-148 {
  from {
    transform: translate3d(1vw, 104vh, 0);
  }
  to {
    transform: translate3d(93vw, -133vh, 0);
  }
}
.circleContainer:nth-child(148) .circle {
  -webkit-animation-delay: 3645ms;
          animation-delay: 3645ms;
}
.circleContainer:nth-child(149) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-149;
          animation-name: move-frames-149;
  -webkit-animation-duration: 31976ms;
          animation-duration: 31976ms;
  -webkit-animation-delay: 26546ms;
          animation-delay: 26546ms;
}
@-webkit-keyframes move-frames-149 {
  from {
    transform: translate3d(27vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
@keyframes move-frames-149 {
  from {
    transform: translate3d(27vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
.circleContainer:nth-child(149) .circle {
  -webkit-animation-delay: 315ms;
          animation-delay: 315ms;
}
.circleContainer:nth-child(150) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-150;
          animation-name: move-frames-150;
  -webkit-animation-duration: 32985ms;
          animation-duration: 32985ms;
  -webkit-animation-delay: 15932ms;
          animation-delay: 15932ms;
}
@-webkit-keyframes move-frames-150 {
  from {
    transform: translate3d(22vw, 101vh, 0);
  }
  to {
    transform: translate3d(23vw, -123vh, 0);
  }
}
@keyframes move-frames-150 {
  from {
    transform: translate3d(22vw, 101vh, 0);
  }
  to {
    transform: translate3d(23vw, -123vh, 0);
  }
}
.circleContainer:nth-child(150) .circle {
  -webkit-animation-delay: 2370ms;
          animation-delay: 2370ms;
}
.circleContainer:nth-child(151) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-151;
          animation-name: move-frames-151;
  -webkit-animation-duration: 29783ms;
          animation-duration: 29783ms;
  -webkit-animation-delay: 36021ms;
          animation-delay: 36021ms;
}
@-webkit-keyframes move-frames-151 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(1vw, -118vh, 0);
  }
}
@keyframes move-frames-151 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(1vw, -118vh, 0);
  }
}
.circleContainer:nth-child(151) .circle {
  -webkit-animation-delay: 1462ms;
          animation-delay: 1462ms;
}
.circleContainer:nth-child(152) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-152;
          animation-name: move-frames-152;
  -webkit-animation-duration: 31467ms;
          animation-duration: 31467ms;
  -webkit-animation-delay: 33420ms;
          animation-delay: 33420ms;
}
@-webkit-keyframes move-frames-152 {
  from {
    transform: translate3d(71vw, 108vh, 0);
  }
  to {
    transform: translate3d(31vw, -132vh, 0);
  }
}
@keyframes move-frames-152 {
  from {
    transform: translate3d(71vw, 108vh, 0);
  }
  to {
    transform: translate3d(31vw, -132vh, 0);
  }
}
.circleContainer:nth-child(152) .circle {
  -webkit-animation-delay: 1831ms;
          animation-delay: 1831ms;
}
.circleContainer:nth-child(153) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-153;
          animation-name: move-frames-153;
  -webkit-animation-duration: 32255ms;
          animation-duration: 32255ms;
  -webkit-animation-delay: 29907ms;
          animation-delay: 29907ms;
}
@-webkit-keyframes move-frames-153 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }
  to {
    transform: translate3d(15vw, -129vh, 0);
  }
}
@keyframes move-frames-153 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }
  to {
    transform: translate3d(15vw, -129vh, 0);
  }
}
.circleContainer:nth-child(153) .circle {
  -webkit-animation-delay: 1696ms;
          animation-delay: 1696ms;
}
.circleContainer:nth-child(154) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-154;
          animation-name: move-frames-154;
  -webkit-animation-duration: 35135ms;
          animation-duration: 35135ms;
  -webkit-animation-delay: 4869ms;
          animation-delay: 4869ms;
}
@-webkit-keyframes move-frames-154 {
  from {
    transform: translate3d(42vw, 105vh, 0);
  }
  to {
    transform: translate3d(99vw, -133vh, 0);
  }
}
@keyframes move-frames-154 {
  from {
    transform: translate3d(42vw, 105vh, 0);
  }
  to {
    transform: translate3d(99vw, -133vh, 0);
  }
}
.circleContainer:nth-child(154) .circle {
  -webkit-animation-delay: 1286ms;
          animation-delay: 1286ms;
}
.circleContainer:nth-child(155) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-155;
          animation-name: move-frames-155;
  -webkit-animation-duration: 32034ms;
          animation-duration: 32034ms;
  -webkit-animation-delay: 30909ms;
          animation-delay: 30909ms;
}
@-webkit-keyframes move-frames-155 {
  from {
    transform: translate3d(92vw, 105vh, 0);
  }
  to {
    transform: translate3d(23vw, -113vh, 0);
  }
}
@keyframes move-frames-155 {
  from {
    transform: translate3d(92vw, 105vh, 0);
  }
  to {
    transform: translate3d(23vw, -113vh, 0);
  }
}
.circleContainer:nth-child(155) .circle {
  -webkit-animation-delay: 688ms;
          animation-delay: 688ms;
}
.circleContainer:nth-child(156) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-156;
          animation-name: move-frames-156;
  -webkit-animation-duration: 32059ms;
          animation-duration: 32059ms;
  -webkit-animation-delay: 9624ms;
          animation-delay: 9624ms;
}
@-webkit-keyframes move-frames-156 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(62vw, -136vh, 0);
  }
}
@keyframes move-frames-156 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(62vw, -136vh, 0);
  }
}
.circleContainer:nth-child(156) .circle {
  -webkit-animation-delay: 688ms;
          animation-delay: 688ms;
}
.circleContainer:nth-child(157) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-157;
          animation-name: move-frames-157;
  -webkit-animation-duration: 28944ms;
          animation-duration: 28944ms;
  -webkit-animation-delay: 30052ms;
          animation-delay: 30052ms;
}
@-webkit-keyframes move-frames-157 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(45vw, -117vh, 0);
  }
}
@keyframes move-frames-157 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(45vw, -117vh, 0);
  }
}
.circleContainer:nth-child(157) .circle {
  -webkit-animation-delay: 3535ms;
          animation-delay: 3535ms;
}
.circleContainer:nth-child(158) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-158;
          animation-name: move-frames-158;
  -webkit-animation-duration: 31074ms;
          animation-duration: 31074ms;
  -webkit-animation-delay: 13743ms;
          animation-delay: 13743ms;
}
@-webkit-keyframes move-frames-158 {
  from {
    transform: translate3d(53vw, 101vh, 0);
  }
  to {
    transform: translate3d(9vw, -104vh, 0);
  }
}
@keyframes move-frames-158 {
  from {
    transform: translate3d(53vw, 101vh, 0);
  }
  to {
    transform: translate3d(9vw, -104vh, 0);
  }
}
.circleContainer:nth-child(158) .circle {
  -webkit-animation-delay: 3983ms;
          animation-delay: 3983ms;
}
.circleContainer:nth-child(159) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-159;
          animation-name: move-frames-159;
  -webkit-animation-duration: 32071ms;
          animation-duration: 32071ms;
  -webkit-animation-delay: 3254ms;
          animation-delay: 3254ms;
}
@-webkit-keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 101vh, 0);
  }
  to {
    transform: translate3d(69vw, -117vh, 0);
  }
}
@keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 101vh, 0);
  }
  to {
    transform: translate3d(69vw, -117vh, 0);
  }
}
.circleContainer:nth-child(159) .circle {
  -webkit-animation-delay: 2019ms;
          animation-delay: 2019ms;
}
.circleContainer:nth-child(160) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-160;
          animation-name: move-frames-160;
  -webkit-animation-duration: 31188ms;
          animation-duration: 31188ms;
  -webkit-animation-delay: 33336ms;
          animation-delay: 33336ms;
}
@-webkit-keyframes move-frames-160 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(69vw, -130vh, 0);
  }
}
@keyframes move-frames-160 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(69vw, -130vh, 0);
  }
}
.circleContainer:nth-child(160) .circle {
  -webkit-animation-delay: 3551ms;
          animation-delay: 3551ms;
}
.circleContainer:nth-child(161) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-161;
          animation-name: move-frames-161;
  -webkit-animation-duration: 34618ms;
          animation-duration: 34618ms;
  -webkit-animation-delay: 11469ms;
          animation-delay: 11469ms;
}
@-webkit-keyframes move-frames-161 {
  from {
    transform: translate3d(41vw, 101vh, 0);
  }
  to {
    transform: translate3d(44vw, -114vh, 0);
  }
}
@keyframes move-frames-161 {
  from {
    transform: translate3d(41vw, 101vh, 0);
  }
  to {
    transform: translate3d(44vw, -114vh, 0);
  }
}
.circleContainer:nth-child(161) .circle {
  -webkit-animation-delay: 456ms;
          animation-delay: 456ms;
}
.circleContainer:nth-child(162) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-162;
          animation-name: move-frames-162;
  -webkit-animation-duration: 34120ms;
          animation-duration: 34120ms;
  -webkit-animation-delay: 14765ms;
          animation-delay: 14765ms;
}
@-webkit-keyframes move-frames-162 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }
  to {
    transform: translate3d(56vw, -110vh, 0);
  }
}
@keyframes move-frames-162 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }
  to {
    transform: translate3d(56vw, -110vh, 0);
  }
}
.circleContainer:nth-child(162) .circle {
  -webkit-animation-delay: 1256ms;
          animation-delay: 1256ms;
}
.circleContainer:nth-child(163) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-163;
          animation-name: move-frames-163;
  -webkit-animation-duration: 32757ms;
          animation-duration: 32757ms;
  -webkit-animation-delay: 33330ms;
          animation-delay: 33330ms;
}
@-webkit-keyframes move-frames-163 {
  from {
    transform: translate3d(61vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
@keyframes move-frames-163 {
  from {
    transform: translate3d(61vw, 103vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
.circleContainer:nth-child(163) .circle {
  -webkit-animation-delay: 2304ms;
          animation-delay: 2304ms;
}
.circleContainer:nth-child(164) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-164;
          animation-name: move-frames-164;
  -webkit-animation-duration: 36609ms;
          animation-duration: 36609ms;
  -webkit-animation-delay: 17580ms;
          animation-delay: 17580ms;
}
@-webkit-keyframes move-frames-164 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(78vw, -125vh, 0);
  }
}
@keyframes move-frames-164 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }
  to {
    transform: translate3d(78vw, -125vh, 0);
  }
}
.circleContainer:nth-child(164) .circle {
  -webkit-animation-delay: 3294ms;
          animation-delay: 3294ms;
}
.circleContainer:nth-child(165) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-165;
          animation-name: move-frames-165;
  -webkit-animation-duration: 31333ms;
          animation-duration: 31333ms;
  -webkit-animation-delay: 15069ms;
          animation-delay: 15069ms;
}
@-webkit-keyframes move-frames-165 {
  from {
    transform: translate3d(33vw, 107vh, 0);
  }
  to {
    transform: translate3d(27vw, -125vh, 0);
  }
}
@keyframes move-frames-165 {
  from {
    transform: translate3d(33vw, 107vh, 0);
  }
  to {
    transform: translate3d(27vw, -125vh, 0);
  }
}
.circleContainer:nth-child(165) .circle {
  -webkit-animation-delay: 3288ms;
          animation-delay: 3288ms;
}
.circleContainer:nth-child(166) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-166;
          animation-name: move-frames-166;
  -webkit-animation-duration: 29484ms;
          animation-duration: 29484ms;
  -webkit-animation-delay: 10905ms;
          animation-delay: 10905ms;
}
@-webkit-keyframes move-frames-166 {
  from {
    transform: translate3d(23vw, 110vh, 0);
  }
  to {
    transform: translate3d(85vw, -139vh, 0);
  }
}
@keyframes move-frames-166 {
  from {
    transform: translate3d(23vw, 110vh, 0);
  }
  to {
    transform: translate3d(85vw, -139vh, 0);
  }
}
.circleContainer:nth-child(166) .circle {
  -webkit-animation-delay: 1682ms;
          animation-delay: 1682ms;
}
.circleContainer:nth-child(167) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-167;
          animation-name: move-frames-167;
  -webkit-animation-duration: 30323ms;
          animation-duration: 30323ms;
  -webkit-animation-delay: 3999ms;
          animation-delay: 3999ms;
}
@-webkit-keyframes move-frames-167 {
  from {
    transform: translate3d(7vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -132vh, 0);
  }
}
@keyframes move-frames-167 {
  from {
    transform: translate3d(7vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -132vh, 0);
  }
}
.circleContainer:nth-child(167) .circle {
  -webkit-animation-delay: 1359ms;
          animation-delay: 1359ms;
}
.circleContainer:nth-child(168) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-168;
          animation-name: move-frames-168;
  -webkit-animation-duration: 29206ms;
          animation-duration: 29206ms;
  -webkit-animation-delay: 7129ms;
          animation-delay: 7129ms;
}
@-webkit-keyframes move-frames-168 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
@keyframes move-frames-168 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
.circleContainer:nth-child(168) .circle {
  -webkit-animation-delay: 3738ms;
          animation-delay: 3738ms;
}
.circleContainer:nth-child(169) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-169;
          animation-name: move-frames-169;
  -webkit-animation-duration: 30772ms;
          animation-duration: 30772ms;
  -webkit-animation-delay: 9045ms;
          animation-delay: 9045ms;
}
@-webkit-keyframes move-frames-169 {
  from {
    transform: translate3d(11vw, 102vh, 0);
  }
  to {
    transform: translate3d(11vw, -131vh, 0);
  }
}
@keyframes move-frames-169 {
  from {
    transform: translate3d(11vw, 102vh, 0);
  }
  to {
    transform: translate3d(11vw, -131vh, 0);
  }
}
.circleContainer:nth-child(169) .circle {
  -webkit-animation-delay: 2722ms;
          animation-delay: 2722ms;
}
.circleContainer:nth-child(170) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-170;
          animation-name: move-frames-170;
  -webkit-animation-duration: 31740ms;
          animation-duration: 31740ms;
  -webkit-animation-delay: 14085ms;
          animation-delay: 14085ms;
}
@-webkit-keyframes move-frames-170 {
  from {
    transform: translate3d(47vw, 108vh, 0);
  }
  to {
    transform: translate3d(90vw, -112vh, 0);
  }
}
@keyframes move-frames-170 {
  from {
    transform: translate3d(47vw, 108vh, 0);
  }
  to {
    transform: translate3d(90vw, -112vh, 0);
  }
}
.circleContainer:nth-child(170) .circle {
  -webkit-animation-delay: 809ms;
          animation-delay: 809ms;
}
.circleContainer:nth-child(171) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-171;
          animation-name: move-frames-171;
  -webkit-animation-duration: 30999ms;
          animation-duration: 30999ms;
  -webkit-animation-delay: 6870ms;
          animation-delay: 6870ms;
}
@-webkit-keyframes move-frames-171 {
  from {
    transform: translate3d(92vw, 106vh, 0);
  }
  to {
    transform: translate3d(40vw, -113vh, 0);
  }
}
@keyframes move-frames-171 {
  from {
    transform: translate3d(92vw, 106vh, 0);
  }
  to {
    transform: translate3d(40vw, -113vh, 0);
  }
}
.circleContainer:nth-child(171) .circle {
  -webkit-animation-delay: 1971ms;
          animation-delay: 1971ms;
}
.circleContainer:nth-child(172) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-172;
          animation-name: move-frames-172;
  -webkit-animation-duration: 35527ms;
          animation-duration: 35527ms;
  -webkit-animation-delay: 5402ms;
          animation-delay: 5402ms;
}
@-webkit-keyframes move-frames-172 {
  from {
    transform: translate3d(97vw, 110vh, 0);
  }
  to {
    transform: translate3d(47vw, -138vh, 0);
  }
}
@keyframes move-frames-172 {
  from {
    transform: translate3d(97vw, 110vh, 0);
  }
  to {
    transform: translate3d(47vw, -138vh, 0);
  }
}
.circleContainer:nth-child(172) .circle {
  -webkit-animation-delay: 3595ms;
          animation-delay: 3595ms;
}
.circleContainer:nth-child(173) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-173;
          animation-name: move-frames-173;
  -webkit-animation-duration: 30415ms;
          animation-duration: 30415ms;
  -webkit-animation-delay: 2753ms;
          animation-delay: 2753ms;
}
@-webkit-keyframes move-frames-173 {
  from {
    transform: translate3d(96vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -129vh, 0);
  }
}
@keyframes move-frames-173 {
  from {
    transform: translate3d(96vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -129vh, 0);
  }
}
.circleContainer:nth-child(173) .circle {
  -webkit-animation-delay: 3455ms;
          animation-delay: 3455ms;
}
.circleContainer:nth-child(174) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-174;
          animation-name: move-frames-174;
  -webkit-animation-duration: 34022ms;
          animation-duration: 34022ms;
  -webkit-animation-delay: 11668ms;
          animation-delay: 11668ms;
}
@-webkit-keyframes move-frames-174 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -106vh, 0);
  }
}
@keyframes move-frames-174 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -106vh, 0);
  }
}
.circleContainer:nth-child(174) .circle {
  -webkit-animation-delay: 130ms;
          animation-delay: 130ms;
}
.circleContainer:nth-child(175) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-175;
          animation-name: move-frames-175;
  -webkit-animation-duration: 35267ms;
          animation-duration: 35267ms;
  -webkit-animation-delay: 31464ms;
          animation-delay: 31464ms;
}
@-webkit-keyframes move-frames-175 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -107vh, 0);
  }
}
@keyframes move-frames-175 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -107vh, 0);
  }
}
.circleContainer:nth-child(175) .circle {
  -webkit-animation-delay: 3555ms;
          animation-delay: 3555ms;
}
.circleContainer:nth-child(176) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-176;
          animation-name: move-frames-176;
  -webkit-animation-duration: 34710ms;
          animation-duration: 34710ms;
  -webkit-animation-delay: 22523ms;
          animation-delay: 22523ms;
}
@-webkit-keyframes move-frames-176 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }
  to {
    transform: translate3d(65vw, -126vh, 0);
  }
}
@keyframes move-frames-176 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }
  to {
    transform: translate3d(65vw, -126vh, 0);
  }
}
.circleContainer:nth-child(176) .circle {
  -webkit-animation-delay: 277ms;
          animation-delay: 277ms;
}
.circleContainer:nth-child(177) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-177;
          animation-name: move-frames-177;
  -webkit-animation-duration: 28926ms;
          animation-duration: 28926ms;
  -webkit-animation-delay: 10703ms;
          animation-delay: 10703ms;
}
@-webkit-keyframes move-frames-177 {
  from {
    transform: translate3d(62vw, 109vh, 0);
  }
  to {
    transform: translate3d(70vw, -125vh, 0);
  }
}
@keyframes move-frames-177 {
  from {
    transform: translate3d(62vw, 109vh, 0);
  }
  to {
    transform: translate3d(70vw, -125vh, 0);
  }
}
.circleContainer:nth-child(177) .circle {
  -webkit-animation-delay: 3865ms;
          animation-delay: 3865ms;
}
.circleContainer:nth-child(178) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-178;
          animation-name: move-frames-178;
  -webkit-animation-duration: 36776ms;
          animation-duration: 36776ms;
  -webkit-animation-delay: 10577ms;
          animation-delay: 10577ms;
}
@-webkit-keyframes move-frames-178 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(85vw, -104vh, 0);
  }
}
@keyframes move-frames-178 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(85vw, -104vh, 0);
  }
}
.circleContainer:nth-child(178) .circle {
  -webkit-animation-delay: 349ms;
          animation-delay: 349ms;
}
.circleContainer:nth-child(179) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-179;
          animation-name: move-frames-179;
  -webkit-animation-duration: 36282ms;
          animation-duration: 36282ms;
  -webkit-animation-delay: 25782ms;
          animation-delay: 25782ms;
}
@-webkit-keyframes move-frames-179 {
  from {
    transform: translate3d(56vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -119vh, 0);
  }
}
@keyframes move-frames-179 {
  from {
    transform: translate3d(56vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -119vh, 0);
  }
}
.circleContainer:nth-child(179) .circle {
  -webkit-animation-delay: 713ms;
          animation-delay: 713ms;
}
.circleContainer:nth-child(180) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-180;
          animation-name: move-frames-180;
  -webkit-animation-duration: 31269ms;
          animation-duration: 31269ms;
  -webkit-animation-delay: 27122ms;
          animation-delay: 27122ms;
}
@-webkit-keyframes move-frames-180 {
  from {
    transform: translate3d(43vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -125vh, 0);
  }
}
@keyframes move-frames-180 {
  from {
    transform: translate3d(43vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -125vh, 0);
  }
}
.circleContainer:nth-child(180) .circle {
  -webkit-animation-delay: 3951ms;
          animation-delay: 3951ms;
}
.circleContainer:nth-child(181) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-181;
          animation-name: move-frames-181;
  -webkit-animation-duration: 36481ms;
          animation-duration: 36481ms;
  -webkit-animation-delay: 13690ms;
          animation-delay: 13690ms;
}
@-webkit-keyframes move-frames-181 {
  from {
    transform: translate3d(10vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -115vh, 0);
  }
}
@keyframes move-frames-181 {
  from {
    transform: translate3d(10vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -115vh, 0);
  }
}
.circleContainer:nth-child(181) .circle {
  -webkit-animation-delay: 882ms;
          animation-delay: 882ms;
}
.circleContainer:nth-child(182) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-182;
          animation-name: move-frames-182;
  -webkit-animation-duration: 29623ms;
          animation-duration: 29623ms;
  -webkit-animation-delay: 27612ms;
          animation-delay: 27612ms;
}
@-webkit-keyframes move-frames-182 {
  from {
    transform: translate3d(23vw, 101vh, 0);
  }
  to {
    transform: translate3d(64vw, -106vh, 0);
  }
}
@keyframes move-frames-182 {
  from {
    transform: translate3d(23vw, 101vh, 0);
  }
  to {
    transform: translate3d(64vw, -106vh, 0);
  }
}
.circleContainer:nth-child(182) .circle {
  -webkit-animation-delay: 3223ms;
          animation-delay: 3223ms;
}
.circleContainer:nth-child(183) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-183;
          animation-name: move-frames-183;
  -webkit-animation-duration: 32053ms;
          animation-duration: 32053ms;
  -webkit-animation-delay: 30554ms;
          animation-delay: 30554ms;
}
@-webkit-keyframes move-frames-183 {
  from {
    transform: translate3d(37vw, 101vh, 0);
  }
  to {
    transform: translate3d(7vw, -111vh, 0);
  }
}
@keyframes move-frames-183 {
  from {
    transform: translate3d(37vw, 101vh, 0);
  }
  to {
    transform: translate3d(7vw, -111vh, 0);
  }
}
.circleContainer:nth-child(183) .circle {
  -webkit-animation-delay: 1437ms;
          animation-delay: 1437ms;
}
.circleContainer:nth-child(184) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-184;
          animation-name: move-frames-184;
  -webkit-animation-duration: 35380ms;
          animation-duration: 35380ms;
  -webkit-animation-delay: 1782ms;
          animation-delay: 1782ms;
}
@-webkit-keyframes move-frames-184 {
  from {
    transform: translate3d(95vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -126vh, 0);
  }
}
@keyframes move-frames-184 {
  from {
    transform: translate3d(95vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -126vh, 0);
  }
}
.circleContainer:nth-child(184) .circle {
  -webkit-animation-delay: 2640ms;
          animation-delay: 2640ms;
}
.circleContainer:nth-child(185) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-185;
          animation-name: move-frames-185;
  -webkit-animation-duration: 30475ms;
          animation-duration: 30475ms;
  -webkit-animation-delay: 4721ms;
          animation-delay: 4721ms;
}
@-webkit-keyframes move-frames-185 {
  from {
    transform: translate3d(98vw, 106vh, 0);
  }
  to {
    transform: translate3d(78vw, -129vh, 0);
  }
}
@keyframes move-frames-185 {
  from {
    transform: translate3d(98vw, 106vh, 0);
  }
  to {
    transform: translate3d(78vw, -129vh, 0);
  }
}
.circleContainer:nth-child(185) .circle {
  -webkit-animation-delay: 2911ms;
          animation-delay: 2911ms;
}
.circleContainer:nth-child(186) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-186;
          animation-name: move-frames-186;
  -webkit-animation-duration: 35712ms;
          animation-duration: 35712ms;
  -webkit-animation-delay: 5166ms;
          animation-delay: 5166ms;
}
@-webkit-keyframes move-frames-186 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(51vw, -126vh, 0);
  }
}
@keyframes move-frames-186 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(51vw, -126vh, 0);
  }
}
.circleContainer:nth-child(186) .circle {
  -webkit-animation-delay: 2427ms;
          animation-delay: 2427ms;
}
.circleContainer:nth-child(187) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-187;
          animation-name: move-frames-187;
  -webkit-animation-duration: 28900ms;
          animation-duration: 28900ms;
  -webkit-animation-delay: 36357ms;
          animation-delay: 36357ms;
}
@-webkit-keyframes move-frames-187 {
  from {
    transform: translate3d(5vw, 104vh, 0);
  }
  to {
    transform: translate3d(28vw, -106vh, 0);
  }
}
@keyframes move-frames-187 {
  from {
    transform: translate3d(5vw, 104vh, 0);
  }
  to {
    transform: translate3d(28vw, -106vh, 0);
  }
}
.circleContainer:nth-child(187) .circle {
  -webkit-animation-delay: 3137ms;
          animation-delay: 3137ms;
}
.circleContainer:nth-child(188) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-188;
          animation-name: move-frames-188;
  -webkit-animation-duration: 32334ms;
          animation-duration: 32334ms;
  -webkit-animation-delay: 32086ms;
          animation-delay: 32086ms;
}
@-webkit-keyframes move-frames-188 {
  from {
    transform: translate3d(19vw, 110vh, 0);
  }
  to {
    transform: translate3d(80vw, -119vh, 0);
  }
}
@keyframes move-frames-188 {
  from {
    transform: translate3d(19vw, 110vh, 0);
  }
  to {
    transform: translate3d(80vw, -119vh, 0);
  }
}
.circleContainer:nth-child(188) .circle {
  -webkit-animation-delay: 3325ms;
          animation-delay: 3325ms;
}
.circleContainer:nth-child(189) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-189;
          animation-name: move-frames-189;
  -webkit-animation-duration: 34977ms;
          animation-duration: 34977ms;
  -webkit-animation-delay: 22711ms;
          animation-delay: 22711ms;
}
@-webkit-keyframes move-frames-189 {
  from {
    transform: translate3d(92vw, 103vh, 0);
  }
  to {
    transform: translate3d(72vw, -124vh, 0);
  }
}
@keyframes move-frames-189 {
  from {
    transform: translate3d(92vw, 103vh, 0);
  }
  to {
    transform: translate3d(72vw, -124vh, 0);
  }
}
.circleContainer:nth-child(189) .circle {
  -webkit-animation-delay: 2745ms;
          animation-delay: 2745ms;
}
.circleContainer:nth-child(190) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-190;
          animation-name: move-frames-190;
  -webkit-animation-duration: 31561ms;
          animation-duration: 31561ms;
  -webkit-animation-delay: 2917ms;
          animation-delay: 2917ms;
}
@-webkit-keyframes move-frames-190 {
  from {
    transform: translate3d(39vw, 102vh, 0);
  }
  to {
    transform: translate3d(16vw, -117vh, 0);
  }
}
@keyframes move-frames-190 {
  from {
    transform: translate3d(39vw, 102vh, 0);
  }
  to {
    transform: translate3d(16vw, -117vh, 0);
  }
}
.circleContainer:nth-child(190) .circle {
  -webkit-animation-delay: 2922ms;
          animation-delay: 2922ms;
}
.circleContainer:nth-child(191) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-191;
          animation-name: move-frames-191;
  -webkit-animation-duration: 28826ms;
          animation-duration: 28826ms;
  -webkit-animation-delay: 21540ms;
          animation-delay: 21540ms;
}
@-webkit-keyframes move-frames-191 {
  from {
    transform: translate3d(73vw, 109vh, 0);
  }
  to {
    transform: translate3d(18vw, -116vh, 0);
  }
}
@keyframes move-frames-191 {
  from {
    transform: translate3d(73vw, 109vh, 0);
  }
  to {
    transform: translate3d(18vw, -116vh, 0);
  }
}
.circleContainer:nth-child(191) .circle {
  -webkit-animation-delay: 2929ms;
          animation-delay: 2929ms;
}
.circleContainer:nth-child(192) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-192;
          animation-name: move-frames-192;
  -webkit-animation-duration: 30706ms;
          animation-duration: 30706ms;
  -webkit-animation-delay: 24370ms;
          animation-delay: 24370ms;
}
@-webkit-keyframes move-frames-192 {
  from {
    transform: translate3d(57vw, 101vh, 0);
  }
  to {
    transform: translate3d(37vw, -116vh, 0);
  }
}
@keyframes move-frames-192 {
  from {
    transform: translate3d(57vw, 101vh, 0);
  }
  to {
    transform: translate3d(37vw, -116vh, 0);
  }
}
.circleContainer:nth-child(192) .circle {
  -webkit-animation-delay: 465ms;
          animation-delay: 465ms;
}
.circleContainer:nth-child(193) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-193;
          animation-name: move-frames-193;
  -webkit-animation-duration: 34780ms;
          animation-duration: 34780ms;
  -webkit-animation-delay: 12969ms;
          animation-delay: 12969ms;
}
@-webkit-keyframes move-frames-193 {
  from {
    transform: translate3d(26vw, 101vh, 0);
  }
  to {
    transform: translate3d(18vw, -104vh, 0);
  }
}
@keyframes move-frames-193 {
  from {
    transform: translate3d(26vw, 101vh, 0);
  }
  to {
    transform: translate3d(18vw, -104vh, 0);
  }
}
.circleContainer:nth-child(193) .circle {
  -webkit-animation-delay: 3682ms;
          animation-delay: 3682ms;
}
.circleContainer:nth-child(194) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-194;
          animation-name: move-frames-194;
  -webkit-animation-duration: 36964ms;
          animation-duration: 36964ms;
  -webkit-animation-delay: 30421ms;
          animation-delay: 30421ms;
}
@-webkit-keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 106vh, 0);
  }
  to {
    transform: translate3d(23vw, -121vh, 0);
  }
}
@keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 106vh, 0);
  }
  to {
    transform: translate3d(23vw, -121vh, 0);
  }
}
.circleContainer:nth-child(194) .circle {
  -webkit-animation-delay: 259ms;
          animation-delay: 259ms;
}
.circleContainer:nth-child(195) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-195;
          animation-name: move-frames-195;
  -webkit-animation-duration: 32287ms;
          animation-duration: 32287ms;
  -webkit-animation-delay: 25799ms;
          animation-delay: 25799ms;
}
@-webkit-keyframes move-frames-195 {
  from {
    transform: translate3d(29vw, 109vh, 0);
  }
  to {
    transform: translate3d(72vw, -126vh, 0);
  }
}
@keyframes move-frames-195 {
  from {
    transform: translate3d(29vw, 109vh, 0);
  }
  to {
    transform: translate3d(72vw, -126vh, 0);
  }
}
.circleContainer:nth-child(195) .circle {
  -webkit-animation-delay: 2137ms;
          animation-delay: 2137ms;
}
.circleContainer:nth-child(196) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-196;
          animation-name: move-frames-196;
  -webkit-animation-duration: 34634ms;
          animation-duration: 34634ms;
  -webkit-animation-delay: 31571ms;
          animation-delay: 31571ms;
}
@-webkit-keyframes move-frames-196 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(79vw, -131vh, 0);
  }
}
@keyframes move-frames-196 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(79vw, -131vh, 0);
  }
}
.circleContainer:nth-child(196) .circle {
  -webkit-animation-delay: 2490ms;
          animation-delay: 2490ms;
}
.circleContainer:nth-child(197) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-197;
          animation-name: move-frames-197;
  -webkit-animation-duration: 32578ms;
          animation-duration: 32578ms;
  -webkit-animation-delay: 31071ms;
          animation-delay: 31071ms;
}
@-webkit-keyframes move-frames-197 {
  from {
    transform: translate3d(87vw, 109vh, 0);
  }
  to {
    transform: translate3d(54vw, -127vh, 0);
  }
}
@keyframes move-frames-197 {
  from {
    transform: translate3d(87vw, 109vh, 0);
  }
  to {
    transform: translate3d(54vw, -127vh, 0);
  }
}
.circleContainer:nth-child(197) .circle {
  -webkit-animation-delay: 1323ms;
          animation-delay: 1323ms;
}
.circleContainer:nth-child(198) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-198;
          animation-name: move-frames-198;
  -webkit-animation-duration: 30773ms;
          animation-duration: 30773ms;
  -webkit-animation-delay: 1046ms;
          animation-delay: 1046ms;
}
@-webkit-keyframes move-frames-198 {
  from {
    transform: translate3d(73vw, 106vh, 0);
  }
  to {
    transform: translate3d(22vw, -125vh, 0);
  }
}
@keyframes move-frames-198 {
  from {
    transform: translate3d(73vw, 106vh, 0);
  }
  to {
    transform: translate3d(22vw, -125vh, 0);
  }
}
.circleContainer:nth-child(198) .circle {
  -webkit-animation-delay: 94ms;
          animation-delay: 94ms;
}
.circleContainer:nth-child(199) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-199;
          animation-name: move-frames-199;
  -webkit-animation-duration: 32889ms;
          animation-duration: 32889ms;
  -webkit-animation-delay: 16026ms;
          animation-delay: 16026ms;
}
@-webkit-keyframes move-frames-199 {
  from {
    transform: translate3d(37vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -111vh, 0);
  }
}
@keyframes move-frames-199 {
  from {
    transform: translate3d(37vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -111vh, 0);
  }
}
.circleContainer:nth-child(199) .circle {
  -webkit-animation-delay: 3017ms;
          animation-delay: 3017ms;
}
.circleContainer:nth-child(200) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-200;
          animation-name: move-frames-200;
  -webkit-animation-duration: 29482ms;
          animation-duration: 29482ms;
  -webkit-animation-delay: 4123ms;
          animation-delay: 4123ms;
}
@-webkit-keyframes move-frames-200 {
  from {
    transform: translate3d(1vw, 110vh, 0);
  }
  to {
    transform: translate3d(82vw, -122vh, 0);
  }
}
@keyframes move-frames-200 {
  from {
    transform: translate3d(1vw, 110vh, 0);
  }
  to {
    transform: translate3d(82vw, -122vh, 0);
  }
}
.circleContainer:nth-child(200) .circle {
  -webkit-animation-delay: 581ms;
          animation-delay: 581ms;
}

.message {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: white;
  font-family: "Josefin Slab", serif;
  line-height: 27px;
  font-size: 18px;
  text-align: right;
  pointer-events: none;
  -webkit-animation: message-frames 1.5s ease 5s forwards;
          animation: message-frames 1.5s ease 5s forwards;
  opacity: 0;
}
@-webkit-keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}