.sortArrow {
  -webkit-animation: all 2s linear;
  -moz-animation: all 2s linear;
  animation: all 2s linear;
}
.arrowUp {
  transform: rotate(0);
}
.arrowDown {
  transform: rotate(180deg);
}
