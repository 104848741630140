.tableSj {
  font-family: "Source Code Pro", monospace;
  border-collapse: collapse;
  width: 100%;
}
.headerSj {
  font-family: "Source Code Pro", monospace;
  /* border-collapse: collapse; */
  width: 100%;
}

.tableSj :global(tbody td) {
  padding-top: 2px;
  padding-bottom: 2px;
}

.tableSj :global(td),
.tableSj :global(th) {
  border: 1px solid;
  text-align: left;
  padding: 8px;
}

.headerSj :global(th) {
  text-align: left;
  padding: 0;
}

.code {
  font-family: "Source Code Pro", monospace;
}

.noWrap {
  white-space: nowrap;
}

.tableACC {
  font-family: "Source Code Pro", monospace;
  border-collapse: collapse;
  width: 100%;
}

.tableACC :global(td),
.tableACC :global(th) {
  border: 1px solid;
  padding: 8px;
  text-align: center;
  font-weight: 300;
}